import React from "react";
import { Frame as PolarisFrame } from "@shopify/polaris";
import { createBreakpoint } from "react-use";
import { SmallScreenSize } from "@src/config";
import { AppBaseUrl } from "@src/config";
import { BookingFlowTopBar } from "./top-bar";
import "./styles.scss";

const useBreakpoint = createBreakpoint({
  M: SmallScreenSize + 1,
  S: SmallScreenSize,
});

export const BookingFlowFrame = (props) => {
  const breakpoint = useBreakpoint();

  const customPropertyOverrideClasses =
    "CustomFrameOverrides";

  const logoWidth = breakpoint == "S" ? 140 : 200;

  const logo = {
    width: logoWidth,
    topBarSource: "/logo+name.svg",
    contextualSaveBarSource: "/logo+name.svg",
    url: AppBaseUrl,
    accessibilityLabel: "HomeBreeze",
  };

  return (
    <div className="BookingFlow">
      <div className={customPropertyOverrideClasses}>
        <PolarisFrame
          topBar={BookingFlowTopBar(breakpoint)}
          logo={logo}
          {...props} />
      </div>
    </div>
  );
};
