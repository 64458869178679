import React, { useCallback, useState } from 'react'
import PropTypes from "prop-types";

import { ActionList, Button, Popover } from '@shopify/polaris';

export const Select = ({ items, selected, title, onChange }) => {
  const [isMenuOpen, setMenuOpen] = useState(false);

  const toggleMenu = useCallback(() => {
    setMenuOpen((isOpen) => !isOpen);
  }, []);

  return (
    items && items.length > 0 ? (
      <Popover
        active={isMenuOpen}
        preferredAlignment="left"
        activator={(
          <Button onClick={toggleMenu} disclosure>
            {items.find((item) => item.value == selected).label}
          </Button>
        )}
        onClose={toggleMenu}
      >
        <Popover.Pane fixed>
          <Popover.Section>
            <p>{title}</p>
          </Popover.Section>
        </Popover.Pane>
        <Popover.Pane>
          <ActionList
            actionRole="menuitem"
            items={items.map((item) => ({
              content: item.label,
              helpText: item.description,
              onAction: () => onChange(item.value),
            }))} />
        </Popover.Pane>
      </Popover>
    ) : null
  );
};
Select.propTypes = {
  items: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
    description: PropTypes.string,
  })),
  selected: PropTypes.string,
  title: PropTypes.string,
  onChange: PropTypes.func,
};
