import React, { useState, useCallback } from "react";
import { TopBar as PolarisTopBar } from "@shopify/polaris";

import { useAuth } from "@src/core";

import { UserMenu } from "./user-menu";
import "./styles.scss";

export const ProAppTopBar = (breakpoint, toggleMobileNavigationActive) => {
  const { proUser, isFrameLoading, userLogout } = useAuth();
  const [userMenuActive, setUserMenuActive] = useState(false);

  const toggleUserMenuActive = useCallback(
    () => setUserMenuActive((userMenuActive) => !userMenuActive),
    []
  );

  const userMenuActions = [
    {
      items: [
        {
          content: "Sign out",
          onAction: userLogout,
        },
      ],
    },
  ];

  const userMenuMarkup = proUser ? (
    <UserMenu
      name={proUser.name || ""}
      detail=""
      initials={proUser && proUser.name ? proUser.name[0].toUpperCase() : "U"}
      actions={userMenuActions}
      onToggle={toggleUserMenuActive}
      open={userMenuActive}
    />
  ) : (
    <></>
  );

  const topbar = isFrameLoading ? (
    <PolarisTopBar
      showNavigationToggle={false}
      onNavigationToggle={toggleMobileNavigationActive}
    ></PolarisTopBar>
  ) : (
    <PolarisTopBar
      showNavigationToggle={false}
      userMenu={userMenuMarkup}
      onNavigationToggle={toggleMobileNavigationActive}
    ></PolarisTopBar>
  );

  return topbar;
};
