import React from "react";
import PropTypes from "prop-types";
import { Badge, Button, Icon } from "@shopify/polaris";
import { TickMinor } from "@shopify/polaris-icons";

import iconSampleProductAvatar from "@src/assets/images/sample/sample-product-avatar.png";
import { ProductCatalog, Price } from "@src/core";

import "./styles.scss";
import { SmallScreenSize } from "@src/config";
import { createBreakpoint } from "react-use";

const useBreakpoint = createBreakpoint({
  M: SmallScreenSize + 1,
  S: SmallScreenSize,
});

/**
 * @typedef {{productCatalog: ProductCatalog, selectProduct: Function, handleOpenRebateModal: Function}} TanklessProductCardProps
 * @param {TanklessProductCardProps} param0
 */
export function TanklessProductCard({
  productCatalog,
  selectProduct,
  handleOpenRebateModal,
}) {
  const breakpoint = useBreakpoint();
  const handleSelectProduct = (productId) => {
    selectProduct(productId);
  };

  const TickIcon = () => <Icon color={"base"} source={TickMinor} />;

  const Tags = () => (
    <div className="tags">
      <div>What's included</div>
      <div className="tag-list">
        {(productCatalog.tags ?? []).map((tag, index) => (
          <Badge icon={TickIcon} key={index}>
            {tag}
          </Badge>
        ))}
      </div>
    </div>
  );

  return (
    <div className={"product-list-item"}>
      {productCatalog.isPopular && (
        <div className="popular">
          <Badge>Popular Choice</Badge>
        </div>
      )}
      <div className="avatar">
        <div className="water-heater">
          <img
            src={
              productCatalog.product.productImage
                ? productCatalog.product.productImage
                : iconSampleProductAvatar
            }
            alt={productCatalog.product.title}
          />
        </div>
      </div>
      <div className="info">
        <div className="name">{productCatalog.product.title}</div>
        {productCatalog.product.tankType == "tankless" && (
          <div className="description">
            {productCatalog.product.description}
          </div>
        )}
        <div className="item-list">
          {(productCatalog.items ?? []).map((item, index) => (
            <div key={index} className="item">
              <span>{item.name}</span>
              <span>{item.description}</span>
            </div>
          ))}
        </div>
      </div>
      <div className="actions">
        {breakpoint === "S" && <Tags />}
        <div className="price-value">
          {Price.installationPriceFormatted(productCatalog)}
        </div>
        <div className="price-description">
          {productCatalog.totalIncentives > 0 && (
            <>
              {`paid upon installation. Post-incentives price of `}
              <strong>{Price.finalPriceFormatted(productCatalog)}</strong>
              {` after rebates & credits of `}
              <strong>{Price.totalIncentivesFormatted(productCatalog)}</strong>
              {`. `}
              <a onClick={() => {handleOpenRebateModal()}} href="#">
                Learn more
              </a>
            </>
          )}
        </div>
        {breakpoint !== "S" && <Tags />}
        <Button
          primary={true}
          fullWidth
          onClick={() => {
            handleSelectProduct(productCatalog.id);
          }}
          size="large"
        >
          Select product
        </Button>
      </div>
    </div>
  );
}
TanklessProductCard.propTypes = {
  productCatalog: PropTypes.object.isRequired,
  selectProduct: PropTypes.func.isRequired,
  handleOpenRebateModal: PropTypes.func.isRequired,
};
