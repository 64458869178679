import React from "react";

import { ExpandableContainer } from "./expandable-container";

import "./styles.scss";

export function FrequentlyAskedQuestions({}) {
  return (
    <div className="frequently-asked-questions">
      <section>
        <h2>Frequently Asked Questions</h2>
        <div className="question-list">
          <ExpandableContainer title="What's included with my installation?">
            Everything you need to get your new water heater installed:
            <br></br>
            <ul>
              <li>A brand new water heater</li>
              <li>Delivery of your new water heater</li>
              <li>
                Professional installation from an experienced, licensed plumbers
              </li>
              <li>
                New water supply lines, gas line, isolation valves for tankless
                water heaters, and other parts needed for installation
              </li>
              <li>
                Free draining, hauling, and disposal of your old water heater
              </li>
              <li>
                Detailed explanation of how to operate your water heater and its
                settings
              </li>
              <li>Assistance with filing for rebates</li>
            </ul>
          </ExpandableContainer>
          <ExpandableContainer title="What's not included with my installation?">
            Occasionally, installers uncover issues that they recommend fixing
            (for example, rotted drywall from leaks or dangerous venting). Any
            fixes will be discussed with you before they start any work that you
            hadn’t already signed off.
          </ExpandableContainer>
          <ExpandableContainer title="What is the correct water heater size for my home?">
            We generally recommending replacing the same size unit that your
            home already has. If you’re upgrading from a tank to a tankless or
            want to re-evaluate your current water heater size, you can use
            these guidelines:
            <br></br>
            <br></br>
            For tank water heaters:
            <ul>
              <li>1 bathroom: 30 gallon tank</li>
              <li>2-3 bathrooms: 40 gallon tank</li>
              <li>3-4 bathrooms: 50 gallon tank</li>
              <li>4+ bathrooms: 75 gallon tank</li>
            </ul>
            For tankless water heaters:
            <ul>
              <li>1-2 bathroom: 6-7 gallons per minute</li>
              <li>2-3 bathrooms: 7-10 gallons per minute</li>
              <li>4+ bathrooms: 10+ gallons per minute</li>
            </ul>
            See the gallons per minute FAQ below for more details on tankless
            sizing.
          </ExpandableContainer>
          <ExpandableContainer title="How many gallons per minute of hot water does my home need?">
            Tankless water heaters use “gallons per minute” to quantify how much
            hot water they can produce. To put the numbers in perspective, here
            are the maximum gpm’s for common household fixtures:
            <br></br>
            <ul>
              <li>
                Showers: 1.8 gallons per minute (the limit set by California)
              </li>
              <li>
                Kitchen sink: 1.8 gallons per minute (the limit set by
                California)
              </li>
              <li>Dishwasher: 2-4 gallons per minute</li>
              <li>Washing Machine: 3-5 gallons per minute</li>
            </ul>
            Keep in mind that these numbers are for <b>total</b> water usage,
            not just hot water - rarely will someone take a shower with 100% hot
            water! Generally, hot water is only about 20% of a home’s total
            water usage.
          </ExpandableContainer>
          <ExpandableContainer title="Can I get a larger water heater than the one HomeBreeze suggested?">
            Yes, if you want to go up in size or explore prices, simply select
            the “Number of Bathrooms” filter, and increase the number.
          </ExpandableContainer>
          <ExpandableContainer title="Will I need to upsize my gas line for a tankless installation?">
            For tankless installations, 130K and lower BTU water heaters can
            often use a 1/2” gas line, while larger sizes need to upsize to a
            3/4” gas line.
          </ExpandableContainer>
          <ExpandableContainer title="What are the pros and cons for switching to tankless?">
            Pros:
            <ul>
              <li>
                <b>Unlimited hot water</b> - Tankless water heaters offer the
                prospect of “unlimited” hot water, as they heat water when
                needed, so you can keep hot water on for as long as you want.
                Tank water heaters, on the other hand, have a limited supply and
                eventually run out of hot water
              </li>
              <li>
                <b>More energy efficient</b> - Tankless water heaters use 10-20%
                less energy compared to tanks, saving you money on your gas bill
                every month
              </li>
              <li>
                <b>Take up less space</b> - Tankless units are smaller than
                tanks
              </li>
              <li>
                <b>Less risk of major leak</b> - Because tankless units don’t
                hold a large quantity of water, there’s less risk of a major
                leak, which can cost thousands of dollars in damage
              </li>
              <li>
                <b>Last longer</b> - Tankless units, when properly maintained,
                can last between 15-25 years, compared to 8-12 for most tanks
              </li>
              <li>
                <b>Rebates & tax credits</b> - Highly efficient tankless units
                qualify for up to $1,600 back in rebates and tax credits
              </li>
            </ul>
            Cons:
            <ul>
              <li>
                <b>Capacity limits</b> - Tankless water heaters produce up to a
                certain amount of hot water per minute. If the unit is too
                small, it may not be able to produce enough water to meet a
                home’s needs all at once
              </li>
              <li>
                <b>More expensive upfront</b> - Tankless water heaters are more
                expensive to install, as the units are expensive, and the
                installation requires more changes to water, gas, electrical,
                and venting systems
              </li>
              <li>
                <b>Need maintenance</b> - Compared to tanks, it is more
                important to properly maintain a tankless unit. Otherwise, hard
                water can build up and damage the heat exchanger. Learn to do
                this yourself or budget $150 per year for a professional to
                maintain
              </li>
            </ul>
            It’s worth noting that a{" "}
            <b>
              tankless water heater won’t magically deliver “instant” hot water
              to your faucet.
            </b>{" "}
            Hot water takes time to travel through pipes, from your water heater
            to your faucet or shower. If you have problems waiting for hot
            water, a tankless alone won’t fix this. Instead, you will need a
            recirculation pump or a tankless unit with internal recirculation.
          </ExpandableContainer>
          <ExpandableContainer title="Can I get a recirculation pump or a unit with internal recirculation without a dedicated return line?">
            Yes. We can install a crossover valve under the sink in the furthest
            bathroom from the tankless unit. This will ensure that hot water is
            flowing in your pipes during the hours that you use hot water, which
            dramatically reduces the delay in receiving hot water.
          </ExpandableContainer>
          <ExpandableContainer title="How does the rebate process for tankless water heaters work?">
            SoCal Gas (and other gas companies) offer rebates for switching from
            a tank to a tankless water heater. The amount you qualify for
            depends on the Uniform Energy Factor (UEF) of the tankless unit
            (between $600-$1,000). There is also a Federal tax credit available
            for these upgrades of 30% of the project cost, up to $600.
            <br></br>
            <br></br>
            After the installation is complete, we will email you a receipt with
            all of the necessary information for you to claim the rebate, as
            well as a link to the rebate form on the SoCal Gas website. It
            usually takes SoCal Gas 1-3 weeks to send the check.
          </ExpandableContainer>
          <ExpandableContainer title="How does payment work?">
            HomeBreeze accepts payments with credit or debit cards. We do not
            accept checks or cash. You only pay upon completion of the
            installation.
          </ExpandableContainer>
          <ExpandableContainer title="How does our pricing work?">
            We gather information regarding your existing water heater and use
            photos to provide an upfront quote. Pricing is fully transparent and
            honest. Then we schedule the installation. Once your installer is
            onsite, they will do a quick walkthrough to confirm the scope and
            price. Although rare, if scope needs to be modified, any changes
            will be discussed with you before starting work.
          </ExpandableContainer>
          <ExpandableContainer title="Does someone need to visit my home in-person to provide a quote?">
            No! We scope through information gathering and photos. On the day of
            the installation, your installer will do a quick walkthrough to
            confirm the scope. In the rare event of any changes to the scope,
            your installer will discuss it with you before starting work.
          </ExpandableContainer>
          <ExpandableContainer title="Why are tankless installations more expensive than tank water heaters?">
            Not only do the tankless units themselves cost more than tanks, but
            the installations often require significant changes to gas, water,
            electrical, and venting systems. The installations take several
            hours longer than tank installations, and require many more parts.
          </ExpandableContainer>
          <ExpandableContainer title="I have another question. Who can I call?">
            Us! We keep our phones on.{" "}
            <a href="tel:9498228229">(949) 822-8229</a>
          </ExpandableContainer>
        </div>
      </section>
    </div>
  );
}
FrequentlyAskedQuestions.propTypes = {};
