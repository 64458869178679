import { BaseModel } from "@src/core/models/base";
import { Appointment } from "./appointment";
import { Photo } from "./photo";
import { MaterialList } from "./material-list";

/**
 * @typedef TJob
 * @property {number} id
 * @property {boolean} isProFinished
 * @property {string} type
 * @property {string} productTitle
 * @property {Appointment} appointment
 * @property {Array<any>} materialLists
 * @property {Array<any>} photos
 * @property {Array<string>} notes
 * @property {string} scope
 */
export class Job extends BaseModel {
  id = -1;
  isProFinished;
  address;
  type;
  productTitle;
  appointment;
  materialLists;
  photos = [];
  notes;
  scope;

  completed() {
    this.isProFinished = true;
  }

  addPhotos(photos) {
    this.photos.push(...Photo.fromJSONArray(photos));
  }

  deletePhoto(photoIndex) {
    this.photos.splice(photoIndex, 1);
  }

  /** @returns {Job} */
  static fromJSON(json) {
    const job = new Job();
    job.id = json.id;
    job.isProFinished = json.is_pro_finished;
    job.address = json.address;
    job.type = json.type;
    job.productTitle = json.product_title;
    job.appointment = Appointment.fromJSON(json.appointment);
    job.materialLists = MaterialList.fromJSONArray(json.material_list);
    job.photos = Photo.fromJSONArray(json.photos);
    job.notes = json.notes ?? [];
    job.scope = json.scope;

    return job;
  }

  /** @returns {Array<Job>} */
  static fromJSONArray(arrJson) {
    return super.fromJSONArray(arrJson);
  }
}
