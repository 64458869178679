import React from "react";
import { InlineWidget, useCalendlyEventListener } from "react-calendly";
import { useLocation, useNavigate, createSearchParams } from "react-router-dom";
import { Spinner } from "@shopify/polaris";

import { BookingFlowFrame, BookingFlowPage } from "@src/components";
import { API_BASE_URL } from "@src/config";

import "./styles.scss";

export function SchedulePage({ calendlyUrl }) {
  let location = useLocation();
  const navigate = useNavigate();

  const { urlToken } = location.state;
  const [isLoading, setIsLoading] = React.useState(true);
  const [isCalendlyVisible, setIsCalendlyVisible] = React.useState(true);

  React.useEffect(() => {
    (async () => {
      const settings = {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          url_token: urlToken,
        }),
      };
      await fetch(API_BASE_URL + `booking/calendly/reschedule/`, settings);
    })();
  }, []);

  const saveCalendlyData = async (calendlyData) => {
    const settings = {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        url_token: urlToken,
        calendly_data: calendlyData,
      }),
    };

    return await fetch(
      `${API_BASE_URL}booking/calendly/`,
      settings
    );
  };

  const calendlyEventHandler = (e) => {
    setIsLoading(false)
    const calendlyData = e.data;

    if (calendlyData.event === "calendly.event_scheduled") {
      setIsCalendlyVisible(false);
      setIsLoading(true);
      saveCalendlyData(calendlyData)
        .then((response) => {
          if (response.status === 200) {
            const invitee_uuid = calendlyData.payload.invitee.uri.split("/").pop();

            navigate({
              pathname: "/summary/",
              search: createSearchParams({
                invitee_uuid: invitee_uuid,
              }).toString(),
            });
          }
        })
        .catch(console.error);
    }
  };

  const handleBack = () => {
    navigate({
      pathname: "/choose-product/",
      search: createSearchParams({
        url_token: urlToken,
      }).toString(),
    });
  }

  useCalendlyEventListener({
    onDateAndTimeSelected: calendlyEventHandler,
    onEventScheduled: calendlyEventHandler,
    onEventTypeViewed: calendlyEventHandler,
    onProfilePageViewed: calendlyEventHandler,
  });

  const widget = isCalendlyVisible && (
    <InlineWidget
      url={calendlyUrl}
      styles={{ position: "relative", height: "80vh" }}
      pageSettings={{ hideGdprBanner: true }}
    />
  );

  const schedulePageContent = isCalendlyVisible && (
    <div className="schedule-tankless">
      {isLoading && (
        <section className="calendar-loading">
          <Spinner accessibilityLabel="Spinner example" size="large" />
        </section>
      )}
      {widget}
    </div>
  );

  const bookingFlowPage = (
    <BookingFlowPage title="Pick installation date" handleBack={handleBack}>
      {schedulePageContent}
    </BookingFlowPage>
  );

  return <BookingFlowFrame>{bookingFlowPage}</BookingFlowFrame>;
}
