import React from "react";
import PropTypes from "prop-types";

import { useNavigate } from "react-router-dom";
import { Spinner } from "@shopify/polaris";

import iconPhoneCall from "@src/assets/images/booking/phone-call-yellow.svg";
import { ProductCatalogFilter } from "@src/components";
import { API_BASE_URL } from "@src/config";
import { routes } from "@src/routes";

import { FrequentlyAskedQuestions } from "./frequently-asked-questions";
import { TanklessProductCard } from "./tankless-product-card";
import { SocialReviews } from "./social-reviews";
import { RebateModal } from "./rebate-modal";
import { RebateCallout } from "./rebate-callout";
import styles from "./styles.module.scss";

export const ChooseProductTankless = ({
  urlToken,
  products,
  criteria,
  setCriteria,
  isLoading,
  isRefreshAvailable,
}) => {
  const navigate = useNavigate();

  const selectProduct = async (productId) => {
    const settings = {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        url_token: urlToken,
        product_catalog_id: productId,
      }),
    };

    let response = await fetch(
      `${API_BASE_URL}booking/select_product/`,
      settings
    );
    response = await response.json();

    navigate(routes.ScheduleTankless, {
      state: {
        urlToken,
      },
    });
  };

  const [rebateModalOpen, setRebateModalOpen] = React.useState(false);
  const handleOpenRebateModal = () => {
    setRebateModalOpen(true);
  };
  const handleCloseRebateModal = () => {
    setRebateModalOpen(false);
  };

  const maxRebateAmount = React.useMemo(() => {
    const maxTotalIncentives = Math.max(
      0,
      ...products.map((product) => product.totalIncentives)
    );

    return maxTotalIncentives;
  }, [products]);

  return (
    <div className={styles.ChooseProduct}>
      {isLoading && (
        <section className={styles.ChooseProductLoading}>
          <Spinner accessibilityLabel="Spinner example" size="large" />
        </section>
      )}
      {!isLoading && (
        <main className={styles.ChooseProductMain}>
          <section className={styles.ChooseProductCategorySelect}>
            <ProductCatalogFilter
              criteria={criteria}
              productCatalog={products}
              onChange={setCriteria}
            />
          </section>
          {maxRebateAmount > 0 && (
            <section className={styles.ChooseProductIncentives}>
              <RebateCallout
                handleOpenRebateModal={handleOpenRebateModal}
                maxRebateAmount={maxRebateAmount}
              />
            </section>
          )}
          {!isRefreshAvailable && (
            <section className={styles.ChooseProductLoading}>
              <Spinner accessibilityLabel="Loading products" size="large" />
            </section>
          )}
          {isRefreshAvailable && (
            <section className={styles.ChooseProductList}>
              {products.length > 0 &&
                products.map((product, index) => (
                  <TanklessProductCard
                    key={index}
                    productCatalog={product}
                    selectProduct={selectProduct}
                    handleOpenRebateModal={handleOpenRebateModal}
                  />
                ))}
              {products.length == 0 && (
                <div className={styles.ChooseProductListEmpty}>
                  <div className={styles.ChooseProductListEmptyTitle}>
                    No products match current option selections
                  </div>
                  <div className={styles.ChooseProductListEmptyDescription}>
                    Change your current option selections to view available
                    products
                  </div>
                </div>
              )}
            </section>
          )}
        </main>
      )}

      <section className={styles.CallUs}>
        <img src={iconPhoneCall} alt="" />
        <h2>Want other options?</h2>
        <div>
          Call us - we're here to help{" "}
          <a href="tel:949822-8229">(949) 822-8229</a>
        </div>
      </section>

      <SocialReviews />
      <FrequentlyAskedQuestions />
      <RebateModal
        modalOpen={rebateModalOpen}
        handleCloseModal={handleCloseRebateModal}
      />
    </div>
  );
};
ChooseProductTankless.propTypes = {
  urlToken: PropTypes.string,
  products: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      homeType: PropTypes.arrayOf(PropTypes.string),
      currentLocation: PropTypes.string,
      desiredLocation: PropTypes.string,
      isPopular: PropTypes.bool.isRequired,
      basePrice: PropTypes.number.isRequired,
      warranty: PropTypes.number.isRequired,
      totalIncentives: PropTypes.number.isRequired,
      socalGasRebates: PropTypes.number.isRequired,
      federalTaxCredit: PropTypes.number.isRequired,
      finalPrice: PropTypes.number.isRequired,
      installationPrice: PropTypes.number.isRequired,
      stairPrice: PropTypes.number.isRequired,
      product: PropTypes.shape({
        id: PropTypes.number.isRequired,
        unitType: PropTypes.string.isRequired,
        title: PropTypes.string.isRequired,
        brand: PropTypes.string.isRequired,
        description: PropTypes.string.isRequired,
        tankType: PropTypes.string.isRequired,
        productImage: PropTypes.string.isRequired,
        powerType: PropTypes.string.isRequired,
        bathroomCoverages: PropTypes.arrayOf(PropTypes.number).isRequired,
        waterFlowGpm: PropTypes.string.isRequired,
        powerOutputBtu: PropTypes.string,
        homeCoverage: PropTypes.string.isRequired,
      }).isRequired,
    })
  ).isRequired,
  criteria: PropTypes.object.isRequired,
  setCriteria: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  isRefreshAvailable: PropTypes.bool.isRequired,
};
