import analytics from "@src/core/analytics/segment-script";

const { default: segmentAnalytics } = analytics;
segmentAnalytics.load(process.env.REACT_APP_SEGMENT_WRITE_KEY);

const segmentClient = {
    track: (eventName, properties) => window.analytics.track(eventName, properties),
    page: (name, properties, options) => window.analytics.page(name, properties, options),
    setAnonymousId: (id) => window.analytics.setAnonymousId(id),
    identify: (userId, traits) => window.analytics.identify(userId, traits),
    user: () => window.analytics.user ? window.analytics.user() : null,
};

export { segmentClient };
