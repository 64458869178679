import React from "react";
import PropTypes from "prop-types";
import { Form, FormLayout, TextField, Heading, Card, Banner } from "@shopify/polaris";

import "./styles.scss";

export const ContactInfo = ({
  handleInputFieldsBlur,
  handleInputFieldsChanges,
  inputFieldValues,
  isAddressInvalid,
}) => {
  return (
    <div className="contact-info">
      <Card sectioned>
        <Heading>Contact &amp; home information</Heading>
        {isAddressInvalid && (
          <div className="mb-4">
            <Banner title="Invalid address" status="critical" />
          </div>
        )}
        <Form>
          <FormLayout>
            <FormLayout.Group>
              <TextField
                type="text"
                label="First name"
                value={inputFieldValues.firstName.value}
                error={
                  inputFieldValues.firstName.errorMsg === ""
                    ? false
                    : inputFieldValues.firstName.errorMsg
                }
                onChange={(value) =>
                  handleInputFieldsChanges("firstName", value)
                }
                onBlur={(value) =>
                  handleInputFieldsBlur("firstName", value)
                }
              />
              <TextField
                type="text"
                label="Last name"
                value={inputFieldValues.lastName.value}
                error={
                  inputFieldValues.lastName.errorMsg === ""
                    ? false
                    : inputFieldValues.lastName.errorMsg
                }
                onChange={(value) =>
                  handleInputFieldsChanges("lastName", value)
                }
                onBlur={(value) =>
                  handleInputFieldsBlur("lastName", value)
                }
              />
            </FormLayout.Group>
            <FormLayout.Group>
              <TextField
                type="email"
                label="Email"
                name="email"
                value={inputFieldValues.email.value}
                error={
                  inputFieldValues.email.errorMsg === ""
                    ? false
                    : inputFieldValues.email.errorMsg
                }
                onChange={(value) => handleInputFieldsChanges("email", value)}
                onBlur={(value) =>
                  handleInputFieldsBlur("email", value)
                }
              />
              <TextField
                type="tel"
                label="Phone number"
                value={inputFieldValues.phone.value}
                error={
                  inputFieldValues.phone.errorMsg === ""
                    ? false
                    : inputFieldValues.phone.errorMsg
                }
                onChange={(value) =>
                  handleInputFieldsChanges("phone", value)
                }
                onBlur={(value) =>
                  handleInputFieldsBlur("phone", value)
                }
              />
            </FormLayout.Group>
            <div className="grid grid-cols-3 gap-6">
              <div className="col-span-3 sm:col-span-2">
                <TextField
                  type="text"
                  label="Address"
                  value={inputFieldValues.address.value}
                  error={
                    inputFieldValues.address.errorMsg === ""
                      ? isAddressInvalid
                      : inputFieldValues.address.errorMsg
                  }
                  onChange={(value) =>
                    handleInputFieldsChanges("address", value)
                  }
                  onBlur={(value) =>
                    handleInputFieldsBlur("address", value)
                  }
                />
              </div>
              <div className="col-span-3 sm:col-span-1">
                <TextField
                  type="text"
                  label="Apt/Unit # (optional)"
                  value={inputFieldValues.aptUnit.value}
                  error={isAddressInvalid}
                  onChange={(value) =>
                    handleInputFieldsChanges("aptUnit", value)
                  }
                  onBlur={(value) =>
                    handleInputFieldsBlur("aptUnit", value)
                  }
                />
              </div>
            </div>
            <div className="grid grid-cols-3 gap-6">
              <div className="col-span-3 sm:col-span-2">
                <TextField
                  type="text"
                  label="City"
                  value={inputFieldValues.city.value}
                  error={
                    inputFieldValues.city.errorMsg === ""
                      ? isAddressInvalid
                      : inputFieldValues.city.errorMsg
                  }
                  onChange={(value) => handleInputFieldsChanges("city", value)}
                  onBlur={(value) =>
                    handleInputFieldsBlur("city", value)
                  }
                />
              </div>
              <div className="col-span-3 sm:col-span-1">
                <TextField
                  type="text"
                  label="Postal code"
                  value={inputFieldValues.zipCode.value}
                  error={
                    inputFieldValues.zipCode.errorMsg === ""
                      ? isAddressInvalid
                      : inputFieldValues.zipCode.errorMsg
                  }
                  onChange={(value) => handleInputFieldsChanges("zipCode", value)}
                  onBlur={(value) =>
                    handleInputFieldsBlur("zipCode", value)
                  }
                />
              </div>
            </div>

            <FormLayout.Group condensed>
              <TextField
                id="contact-info-gate-code"
                type="text"
                label="Gate code (optional)"
                value={inputFieldValues.gateCode.value}
                onChange={(value) =>
                  handleInputFieldsChanges("gateCode", value)
                }
                onBlur={(value) =>
                  handleInputFieldsBlur("gateCode", value)
                }
              />
            </FormLayout.Group>
          </FormLayout>
        </Form>
      </Card>
    </div>
  );
};
ContactInfo.propTypes = {
  handleInputFieldsBlur: PropTypes.func.isRequired,
  handleInputFieldsChanges: PropTypes.func.isRequired,
  inputFieldValues: PropTypes.object.isRequired,
  isAddressInvalid: PropTypes.bool.isRequired,
};
