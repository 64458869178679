import React from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { Button } from "@shopify/polaris";

import { BookingFlowFrame, BookingFlowPage } from "@src/components";
import { ProductType } from "@src/config/constants";
import { routes, redirectTo, parseSearchParams } from "@src/routes";

import "./styles.scss";

export function SuccessPage({}) {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const [type, setType] = React.useState(null);
  const [firstName, setFirstName] = React.useState(null);

  React.useEffect(() => {
    parseLocation();
  }, [searchParams]);

  function parseLocation() {
    const { type, firstname } = parseSearchParams(searchParams);
    if (!type) {
      navigate(
        redirectTo({
          route: routes.Success,
          searches: { type: ProductType.Tank },
        })
      );
      return;
    }

    setType(type);
    setFirstName(firstname);
  }

  const successContent = (
    <div className="success-page">
      <div className="success-page-wrapper">
        <h1 className="Polaris-Header-Title">You're all set, {firstName}!</h1>
        <div className="description">
          Soon, you will receive an email confirmation and SMS from +1 (949) 822-8229
        </div>
        <div className="message">
          Reply to our confirmation SMS with photos of your current water heater
          to help ensure a smooth and quick installation.
        </div>
        <Button
          primary={true}
          size="large"
          onClick={() => {
            window.location.replace(process.env.REACT_APP_WEBFLOW_URL);
          }}
        >
            Done
        </Button>
      </div>
    </div>
  );

  const successPage = (
    <BookingFlowPage noHeader>{successContent}</BookingFlowPage>
  );

  return <BookingFlowFrame>{successPage}</BookingFlowFrame>;
}
