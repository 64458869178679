import { BaseCaseModel } from "@src/core/models/base";

export class ProductCatalog extends BaseCaseModel {
  id;
  basePrice;
  finalPrice;
  stairPrice;
  installationPrice;
  currentLocation;
  desiredLocation;
  federalTaxCredit;
  totalIncentives;
  homeTypes;
  isPopular;
  socalGasRebates;
  warranty;
  product;
  uniformEnergyFactor;
  warrantyUrl;
}
