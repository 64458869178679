import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { TextStyle, Card } from "@shopify/polaris";

import {
  formatJobDate,
} from "@src/utils";

import "./styles.scss";

export const AppointmentDetails = ({
  appointment,
  handleBack,
}) => {
  const [formattedDate, setFormattedDate] = useState("Loading Service Date...");

  useEffect(() => {
    if (appointment.date !== undefined) {
      setFormattedDate(formatJobDate(appointment.date));
    }
  }, [appointment]);

  return (
    <Card
      title="Service appointment"
      actions={[
        {
          content: (
            <span className="Polaris-Button not-small-screen appt-details">
              <span className="Polaris-Button__Content">
                <span className="Polaris-Button__Text">Edit</span>
              </span>
            </span>
          ),
          onAction: () => { handleBack() }
        },
      ]}
      primaryFooterAction={{
        content: (
          <span className="Polaris-Button Polaris-Button--sizeSlim small-screen appt-details">
            <span className="Polaris-Button__Content">
              <span className="Polaris-Button__Text" onClick={() => { handleBack() }}>Edit</span>
            </span>
          </span>
        ),
      }}
    >
      <div className="Polaris-Card__Section serviceAppointmentTime">
        <TextStyle variation="subdued">{formattedDate}</TextStyle>
      </div>
    </Card>
  );
};
AppointmentDetails.propTypes = {
  appointment: PropTypes.object.isRequired,
  handleBack: PropTypes.func.isRequired,
};
