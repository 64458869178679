import React from "react";
import PropTypes from "prop-types";

import { Tabs } from "@shopify/polaris";
import "./styles.scss";

export function CustomTabs({
  selected,
  setSelected,
  tabs,
  ...props
}) {
  const handleTabChange = (selectedTabIndex) => {
    setSelected(selectedTabIndex);
  };

  return (
    <Tabs tabs={tabs} selected={selected} onSelect={handleTabChange} fitted>
      {props.children}
    </Tabs>
  );
}
CustomTabs.propTypes = {
  selected: PropTypes.number,
  setSelected: PropTypes.func,
  tabs: PropTypes.array,
};
