import { formatCurrencyDollar } from "@src/utils/index";

export class Price {
  static installationPriceFormatted(productCatalog) {
    return formatCurrencyDollar(productCatalog.installationPrice);
  }

  static finalPriceFormatted(productCatalog) {
    return formatCurrencyDollar(productCatalog.finalPrice);
  }

  static totalIncentivesFormatted(productCatalog) {
    if (productCatalog.totalIncentives === 0) {
      return "";
    }

    return formatCurrencyDollar(productCatalog.totalIncentives);
  }
}
