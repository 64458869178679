import React from "react";
import PropTypes from "prop-types";
import { useNavigate } from "react-router-dom";
import { Spinner } from "@shopify/polaris";

import { ProductCatalogFilter } from "@src/components";
import { API_BASE_URL } from "@src/config";
import { Analytics, Product } from "@src/core";
import { routes } from "@src/routes";

import { TankBundleCard } from "./tank-bundle-card";
import { TankCustomCard } from "./tank-custom-card";
import { Brands } from "./brands";
import styles from "./styles.module.scss";

export const ChooseProductTank = ({
  urlToken,
  products,
  criteria,
  setCriteria,
  isLoading,
  isRefreshAvailable,
}) => {
  const navigate = useNavigate();

  const selectProduct = async (productId) => {
    const settings = {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        url_token: urlToken,
        product_catalog_id: productId,
      }),
    };

    let response = await fetch(
      `${API_BASE_URL}booking/select_product/`,
      settings
    );
    response = await response.json();

    navigate(routes.ScheduleTank, {
      state: {
        urlToken,
      },
    });
  };

  return (
    <div className={styles.ChooseProduct}>
      {isLoading && (
        <section className={styles.ChooseProductLoading}>
          <Spinner accessibilityLabel="Spinner example" size="large" />
        </section>
      )}
      {!isLoading && (
        <div className={styles.ChooseProductContent}>
          <section className={styles.ChooseProductCategorySelect}>
            <ProductCatalogFilter
              criteria={criteria}
              productCatalog={products}
              onChange={setCriteria}
            />
          </section>
          {!isRefreshAvailable && (
            <section className={styles.ChooseProductLoading}>
              <Spinner accessibilityLabel="Loading products" size="large" />
            </section>
          )}
          {isRefreshAvailable && (
            <section className={styles.ChooseProductList}>
              {products.length > 0 &&
                products.map((productCatalog, index) => (
                  <TankBundleCard
                    key={index}
                    isPopular={productCatalog.isPopular}
                    onSucess={selectProduct}
                    price={productCatalog.installationPrice}
                    productCatalogId={productCatalog.id}
                    parameters={Product.getTankParameters(productCatalog.product)} />
                ))}
              {products.length == 0 && (
                <div className={styles.ChooseProductListEmpty}>
                  <div className={styles.ChooseProductListEmptyTitle}>
                    No products match current option selections
                  </div>
                  <div className={styles.ChooseProductListEmptyDescription}>
                    Change your current option selections to view available
                    products
                  </div>
                </div>
              )}
            </section>
          )}
          <div className={styles.ChooseProductCustomized}>
            <TankCustomCard/>
          </div>
          <Brands/>
        </div>
      )}
    </div>
  );
}
ChooseProductTank.propTypes = {
  urlToken: PropTypes.string,
  products: PropTypes.arrayOf(PropTypes.shape(
    {
      id: PropTypes.number.isRequired,
      homeTypes: PropTypes.arrayOf(PropTypes.string).isRequired,
      currentLocation: PropTypes.string.isRequired,
      desiredLocation: PropTypes.string.isRequired,
      isPopular: PropTypes.bool.isRequired,
      basePrice: PropTypes.number.isRequired,
      warranty: PropTypes.number.isRequired,
      totalIncentives: PropTypes.number.isRequired,
      socalGasRebates: PropTypes.number.isRequired,
      federalTaxCredit: PropTypes.number.isRequired,
      finalPrice: PropTypes.number.isRequired,
      installationPrice: PropTypes.number.isRequired,
      stairPrice: PropTypes.number.isRequired,
      product: PropTypes.shape({
        id: PropTypes.number.isRequired,
        unitType: PropTypes.string.isRequired,
        title: PropTypes.string.isRequired,
        brand: PropTypes.string.isRequired,
        description: PropTypes.string.isRequired,
        tankType: PropTypes.string.isRequired,
        productImage: PropTypes.string.isRequired,
        powerType: PropTypes.string.isRequired,
        bathroomCoverages: PropTypes.arrayOf(PropTypes.number).isRequired,
        waterFlowGpm: PropTypes.string.isRequired,
        powerOutputBtu: PropTypes.string,
        homeCoverage: PropTypes.string.isRequired,
      }).isRequired,
    }
  )).isRequired,
  criteria: PropTypes.object.isRequired,
  setCriteria: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  isRefreshAvailable: PropTypes.bool.isRequired,
};
