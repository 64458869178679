import { BaseCaseModel } from "@src/core/models/base";

export class Criteria extends BaseCaseModel {
  id;
  homeType;
  powerType;
  tankType;
  bathroomCoverage;
  stairAccess;
  currentLocation;
  relocation;

  static fromJSON(json) {
    const result = super.fromJSON(json);
    result.stairAccess = json.stair_access ? "yes" : "no";
    result.bathroomCoverage = json.bathroom_coverage + "";

    return result;
  }

  /** @returns {object} */
  static toJSON(criteria) {
    const result = super.toJSON(criteria);
    result.stair_access = criteria.stairAccess == "yes";
    return result;
  }
}
