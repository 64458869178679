import { BaseCaseModel } from "@src/core/models/base";
import { formatJobDate } from "@src/utils";

export class Appointment extends BaseCaseModel {
  id;
  date;
  formattedDate;

  /** @returns {Appointment} */
  static fromJSON(json) {
    const appointment = new Appointment();
    appointment.date = json.date;
    appointment.formattedDate = formatJobDate(appointment.date);

    return appointment;
  }
}
