import React, { useState, useEffect, useCallback, useRef, useMemo } from "react";
import { useParams } from "react-router-dom";
import {
  Layout,
  Card,
  Stack,
  Thumbnail,
  Badge,
  Toast,
  Spinner,
} from "@shopify/polaris";
import FormData from "form-data";
import { useTreatments } from "@splitsoftware/splitio-react";

import { Address, ProAppFrame, ProAppPage } from "@src/components";
import { GCS_BASE_URL, SPLITS } from "@src/config";
import { Api, getJob, Job, useAuth } from "@src/core";

import { DeletePhotoModal } from "./delete-photo-modal";
import { JobCompletionModal } from "./job-completion-modal";
import { JobReportModal } from "./job-report-modal";
import { MaterialListLayout } from "./material-list-layout";
import "./styles.scss";

export function JobDetailPage() {
  const pageParams = useParams();
  const treatment = useTreatments([SPLITS.PRO_APP_PAYMENTS]);
  const proAppPayments = treatment[SPLITS.PRO_APP_PAYMENTS].treatment === "on";

  const { isFrameLoading } = useAuth();

  const [toast, setToast] = useState({
    isActive: false,
    message: "",
    isError: false,
  });

  const toggleToastActive = useCallback(
    () => setToast({ ...toast, isActive: !toast.isActive }),
    [toast],
  );

  const toastMarkup = toast.isActive ? (
    <Toast
      content={toast.message}
      error={toast.isError}
      onDismiss={toggleToastActive}
    />
  ) : null;

  const [job, setJob] = useState(new Job());

  const isLoaded = useMemo(() => job.id > 0, [job]);

  const isCompleted = useMemo(() => isLoaded && job.isProFinished, [job]);

  useEffect(() => {
    (async () => {
      try {
        const response = await getJob(pageParams.id);
        setJob(Job.fromJSON(response.data));
      } catch {
        setToast({
          isActive: true,
          message: "There was an issue loading the job information",
          isError: true,
        });
      }
    })();
  }, [pageParams.id]);

  //Complete Job Modal
  const [isCompleteJobModalActive, setIsCompleteJobModalActive] =
    useState(false);
  const onSuccessfullyCompleteJob = useCallback(() => {
    setIsCompleteJobModalActive(false);
    job.completed();

    setToast({
      isActive: true,
      message: "Job completed",
      isError: false,
    });
  }, []);

  const onCancelCompleteJob = useCallback(() => {
    setIsCompleteJobModalActive(false);
  }, []);

  const onFailedCompleteJob = useCallback(() => {
    setToast({
      isActive: true,
      message: "There was an issue completing the job",
      isError: true,
    });
  }, []);

  //Report issue Modal
  const [isReportIssueModalActive, setIsReportIssueModalActive] =
    useState(false);
  const onSuccessfullyReportIssue = useCallback(() => {
    setIsReportIssueModalActive(false);

    setToast({ isActive: true, message: "Issue submitted", isError: false });
  }, []);

  const onFailedReportIssue = useCallback((message = "") => {
    if (message === "") {
      message = "There was an issue submitting the issue";
    }

    setToast({ isActive: true, message, isError: true });
  }, []);

  const onCancelReportIssue = useCallback(() => {
    setIsReportIssueModalActive(false);
  }, []);

  //File upload for photos
  const uploadPhotoRef = useRef();
  const [isUploadLoadingSpinner, setIsUploadLoadingSpinner] = useState(false);
  const [selectedPhoto, setSelectedPhoto] = useState(-1);
  const onChangePhotoUpoad = async (e) => {
    setIsUploadLoadingSpinner(true);

    const photoFormData = new FormData();
    for (let i = 0; i < uploadPhotoRef.current.files.length; i++) {
      photoFormData.append(
        uploadPhotoRef.current.files[i].name,
        uploadPhotoRef.current.files[i],
      );
    }

    try {
      const res = await Api.uploadFiles(
        "pro/job/" + pageParams.id + "/photo/",
        photoFormData,
      );

      job.addPhotos(res.data);

      setToast({
        isActive: true,
        message: "Photo upload completed",
        isError: false,
      });
    } catch (error) {
      setToast({
        isActive: true,
        message: "There was an issue uploading photos",
        isError: true,
      });
    } finally {
      setIsUploadLoadingSpinner(false);
    }
  };

  const onClickPhoto = (photoIndex) => {
    setSelectedPhoto(photoIndex);
  };

  // Delete Photo Modal
  const onCancelDeletePhoto = useCallback(() => {
    setSelectedPhoto(-1);
  }, []);

  const onFailedDeletePhoto = useCallback(() => {
    setSelectedPhoto(-1);
    setToast({
      isActive: true,
      message: "There was an issue removing the photo",
      isError: false,
    });
  }, []);

  const onSuccessfullyDeletePhoto = useCallback(() => {
    job.deletePhoto(selectedPhoto);
    setSelectedPhoto(-1);
    setToast({
      isActive: true,
      message: "Photo successfully removed",
      isError: false,
    });
  }, [job, selectedPhoto]);

  const photoModal = useMemo(() => selectedPhoto !== -1 && (
    <DeletePhotoModal
      photo={job.photos[selectedPhoto]}
      onCancel={onCancelDeletePhoto}
      onFailure={onFailedDeletePhoto}
      onSuccess={onSuccessfullyDeletePhoto}
    />
  ), [selectedPhoto, job.photos]);

  const jobDetailPhotos = isLoaded && (
    <Card.Section
      title="Photos"
      actions={[
        {
          content: "Upload photos",
          onAction: () => uploadPhotoRef.current.click(),
        },
      ]}
    >
      <>
        <input
          type="file"
          multiple
          ref={uploadPhotoRef}
          style={{ display: "none" }}
          onChange={onChangePhotoUpoad}
        />
        <Stack>
          {job.photos.map((photo, index) => (
            <div
              key={index}
              onClick={() => onClickPhoto(index)}
              className="job-scope-photo"
            >
              <Thumbnail
                source={GCS_BASE_URL + photo.uri}
                size="large"
                alt={photo.name}
              />
            </div>
          ))}
          {isUploadLoadingSpinner && (
            <div className="job-scope-photo-spinner">
              <Spinner
                accessibilityLabel="Uploading photo"
                size="large"
              />
            </div>
          )}
        </Stack>
      </>
    </Card.Section>
  );

  const jobDetailContent = isLoaded && (
    <>
      <Layout>
        <Layout.Section>
          <Card title="Details">
            <Card.Section>
              <p>{job.type + ", " + job.productTitle}</p>
            </Card.Section>

            <Card.Section title="TIME">
              <p>{job.appointment.formattedDate}</p>
            </Card.Section>

            {!isCompleted && (
              <Card.Section title="JOB ADDRESS">
                <Address address={job.address}/>
              </Card.Section>
            )}

            {job.notes.length > 0 && (
              <Card.Section title="NOTES">
                {job.notes.map((note, key) => (
                  <div
                    className="job-note-text"
                    key={key}
                    dangerouslySetInnerHTML={{ __html: note }}
                  />
                ))}
              </Card.Section>
            )}

            {jobDetailPhotos}
          </Card>
        </Layout.Section>

        {job.materialLists.map((materialList) => (
          <Layout.Section key={materialList.orderNumber}>
            <MaterialListLayout
              jobId={job.id}
              orderNumber={materialList.orderNumber}
              supplyHouse={materialList.supplyHouse}
            />
          </Layout.Section>
        ))}

        <Layout.Section>
          <Card title={job.scope !== null ? "Job scope" : ""}>
            {job.scope !== null && (
              <Card.Section>
                <div
                  className="job-scope-text"
                  dangerouslySetInnerHTML={{ __html: job.scope }}
                />
              </Card.Section>
            )}
          </Card>
        </Layout.Section>
      </Layout>

      <JobCompletionModal
        isOpen={isCompleteJobModalActive}
        jobId={job.id}
        onCancel={onCancelCompleteJob}
        onFailure={onFailedCompleteJob}
        onSuccess={onSuccessfullyCompleteJob}
      />

      {photoModal}

      <JobReportModal
        isOpen={isReportIssueModalActive}
        jobId={job.id}
        onCancel={onCancelReportIssue}
        onFailure={onFailedReportIssue}
        onSuccess={onSuccessfullyReportIssue}
      />

      {toastMarkup}
    </>
  );

  const jobDetailPage = (
    <>
      {(!isLoaded || isFrameLoading) && (
        <div className="job-loading">Loading Job Data...</div>
      )}

      {isLoaded && !isFrameLoading && !isCompleted && (
        <div className={proAppPayments ? "pro-app-payments-flag" : ""}>
          <ProAppPage
            breadcrumbs={[
              { content: "Job Detail", url: "/site/pro/job-list/" },
            ]}
            title={"Job #" + job.id}
            titleMetadata={
              <Badge status="attention" progress="incomplete">
                Open
              </Badge>
            }
            compactTitle
            primaryAction={
              proAppPayments
                ? {
                    content: "Report issues",
                    accessibilityLabel: "Report issues action",
                    onAction: () => setIsReportIssueModalActive(true),
                  }
                : {
                    content: "Complete Job",
                    disabled: false,
                    onAction: () => setIsCompleteJobModalActive(true),
                  }
            }
            secondaryActions={
              proAppPayments
                ? false
                : [
                    {
                      content: "Report issues",
                      accessibilityLabel: "Report issues action",
                      onAction: () => setIsReportIssueModalActive(true),
                    },
                  ]
            }
          >
            {jobDetailContent}
          </ProAppPage>
        </div>
      )}
      {isLoaded && !isFrameLoading && isCompleted && (
        <ProAppPage
          breadcrumbs={[{ content: "Job Detail", url: "/site/pro/job-list/" }]}
          title={"Job #" + job.id}
          titleMetadata={<Badge progress="complete">Complete</Badge>}
          compactTitle
          primaryAction={
            proAppPayments
              ? {
                  content: "Report issues",
                  accessibilityLabel: "Report issues action",
                  onAction: () => setIsReportIssueModalActive(true),
                }
              : false
          }
          secondaryActions={
            proAppPayments
              ? false
              : [
                  {
                    content: "Report issues",
                    accessibilityLabel: "Report issues action",
                    onAction: () => setIsReportIssueModalActive(true),
                  },
                ]
          }
        >
          {jobDetailContent}
        </ProAppPage>
      )}
    </>
  );

  return <ProAppFrame>{jobDetailPage}</ProAppFrame>;
}
