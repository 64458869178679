import "babel-polyfill";
import React from "react";
import ReactDOM from "react-dom";
import "./index.scss";
import App from "./App";
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import reportWebVitals from "./reportWebVitals";
import { AppProvider } from "@shopify/polaris";
import enTranslations from "@shopify/polaris/locales/en.json";
import "@shopify/polaris/build/esm/styles.css";
import "./assets/styles/global.scss";
import { SPLITIO_CONFIG } from '@src/config';
import { SplitFactory } from '@splitsoftware/splitio-react';

ReactDOM.render(
  <React.StrictMode>
    <AppProvider i18n={enTranslations}>
      <SplitFactory config={SPLITIO_CONFIG} >
        <App />
      </SplitFactory>
    </AppProvider>
  </React.StrictMode>,

  document.getElementById("root")
);

serviceWorkerRegistration.register();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
