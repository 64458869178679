import React, { useState } from "react";
import PropTypes from "prop-types";

import {
  HomeTypes,
  PowerType,
  BathroomCoverage,
  StairType,
  BrandTypes,
} from "@src/config/constants";

import { Button, ChoiceList, Modal, Stack, TextContainer } from '@shopify/polaris';

import styles from "./styles.module.scss";

export const FilterModal = ({ criteria, handleChange, active }) => {
  const [currentCriteria, setCurrentCriteria] = useState(criteria);

  const onChange = (type, value) => {
    setCurrentCriteria((currentValues) => ({
      ...currentValues,
      [type]: value.pop(),
    }));
  };

  const header = (
    <div className={styles.FilterModalHeader}>
      Edit Product
    </div>
  );

  const footer = (
    <Stack distribution="equalSpacing">
      <Button onClick={() => handleChange(criteria)}>Cancel</Button>
      <Button onClick={() => handleChange(currentCriteria)} primary>Done</Button>
    </Stack>
  );

  const stairLabel = (item) => item.description !== undefined
    ? `${item.label} (${item.description})`
    : item.label;

  return (
    <div className={styles.FilterModal}>
      <Modal
        open={active}
        onClose={() => handleChange(criteria)}
        titleHidden={true}
        title=""
        footer={footer}
      >
        <Modal.Section>
          {header}
        </Modal.Section>
        <Modal.Section>
          <TextContainer>
            <div className={styles.FilterModalChoices}>
              <ChoiceList
                title="Brand"
                choices={BrandTypes}
                selected={currentCriteria["brand"]}
                onChange={(value) => onChange("brand", value)}
              />
              <ChoiceList
                title="Home type"
                choices={HomeTypes}
                selected={currentCriteria["homeType"]}
                onChange={(value) => onChange("homeType", value)}
              />
              <ChoiceList
                title="Power type"
                choices={PowerType}
                selected={currentCriteria["powerType"]}
                onChange={(value) => onChange("powerType", value)}
              />
              <ChoiceList
                title="Coverage"
                choices={BathroomCoverage}
                selected={currentCriteria["bathroomCoverage"]}
                onChange={(value) => onChange("bathroomCoverage", value)}
              />
              <ChoiceList
                title="Stair access"
                choices={StairType.map((item) => ({
                  ...item,
                  label: stairLabel(item),
                }))}
                selected={currentCriteria["stairAccess"]}
                onChange={(value) => onChange("stairAccess", value)}
              />
            </div>
          </TextContainer>
        </Modal.Section>
      </Modal>
    </div>
  );
}
FilterModal.propTypes = {
  criteria: PropTypes.shape({
    homeType: PropTypes.string.isRequired,
    powerType: PropTypes.string.isRequired,
    bathroomCoverage: PropTypes.string.isRequired,
    stairAccess: PropTypes.string.isRequired,
    tankType: PropTypes.string.isRequired,
    relocation: PropTypes.string.isRequired,
  }).isRequired,
  handleChange: PropTypes.func.isRequired,
  active: PropTypes.bool.isRequired,
};
