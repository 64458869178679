import React from "react";

const BookingContext = React.createContext({});

export function BookingProvider({ children }) {
  const [urlToken, setUrlToken] = React.useState("");

  const useAsyncError = () => {
    // hacky solution
    // @see: https://medium.com/trabe/catching-asynchronous-errors-in-react-using-error-boundaries-5e8a5fd7b971
    const [_, setError] = React.useState();
    return React.useCallback(
      (e) => {
        setError(() => {
          throw e;
        });
      },
      [setError],
    );
  };

  const memoedValue = React.useMemo(
    () => ({
      urlToken,
      setUrlToken,
      useAsyncError,
    }),
    [urlToken],
  );

  return (
    <BookingContext.Provider value={memoedValue}>
      {children}
    </BookingContext.Provider>
  );
}

export function useBooking() {
  return React.useContext(BookingContext);
}
