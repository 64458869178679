import React, { useState, useMemo, useCallback, useEffect } from "react";
import PropTypes from "prop-types";
import { Modal } from "@shopify/polaris";
import { GCS_BASE_URL } from "@src/config";
import { deletePhoto } from "@src/core/api";
import { Photo } from "@src/core/models/photo";

export function DeletePhotoModal({
  photo,
  onCancel,
  onSuccess,
  onFailure,
}) {
  const [isProcessingRequest, setIsProcessingRequest] = useState(false);
  const onClickDeletePhoto = useCallback(() => {
    (async () => {
      setIsProcessingRequest(true);

      try {
        await deletePhoto(photo.id);
        onSuccess();
      } catch (error) {
        onFailure();
      }
    })();
  }, [photo, onFailure, onSuccess]);

  const primaryAction = useCallback(() => ({
    content: "Delete photo",
    outline: true,
    id: "delete-photo-modal-primary",
    onAction: onClickDeletePhoto,
    ...(isProcessingRequest ? { loading: true } : {}),
  }), [isProcessingRequest, onClickDeletePhoto]);

  const secondaryAction = useCallback(() => ({
    content: "Cancel",
    onAction: onCancel,
    ...(isProcessingRequest ? { disabled: true } : {}),
  }), [isProcessingRequest, onCancel]);

  return (
    <Modal
      open={true}
      title={photo.name}
      onClose={onCancel}
      primaryAction={primaryAction()}
      secondaryActions={[secondaryAction()]}
    >
      <Modal.Section>
        <img
          src={GCS_BASE_URL + photo.uri}
          style={{ objectFit: "cover", width: "100%", height: "100%" }}
        />
      </Modal.Section>
    </Modal>
  );
};
DeletePhotoModal.propTypes = {
  photo: PropTypes.instanceOf(Photo).isRequired,
  onCancel: PropTypes.func.isRequired,
  onFailure: PropTypes.func.isRequired,
  onSuccess: PropTypes.func.isRequired,
};
