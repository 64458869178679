import React from "react";
import { EmptyState } from "@shopify/polaris";

import { BookingFlowFrame } from "@src/components";
import { API_BASE_URL, WEBFLOW_URL } from "@src/config";
import { segmentClient } from "@src/core";

export function BookingAppErrorPage() {
  const user = segmentClient.user();
  const urlToken = user !== null ? user.anonymousId() : "";
  const initiateRestart = React.useCallback(() => {
    fetch(`${API_BASE_URL}booking/restart_flow/${urlToken}`);
    location.href = `${WEBFLOW_URL}?url_token=${urlToken}`;
  }, [urlToken]);

  return (
    <BookingFlowFrame>
      <EmptyState
        heading="An unexpected error occurred."
        action={{ content: "Restart", onAction: initiateRestart }}
        image="/No Results@1x.svg"
        fullWidth
      >
        <span>
          Please restart the product selection process by clicking the button
          below.
        </span>
        <br />
        <small>
          {"If this error persists, please reach out to us at "}
          <a href="mailto:care@homebreeze.com">care@homebreeze.com</a>.
        </small>
      </EmptyState>
    </BookingFlowFrame>
  );
}
