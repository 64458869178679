import React, { useCallback, useState } from "react";
import PropTypes from "prop-types";

import styles from "./styles.module.scss";
import { Button, Thumbnail } from "@shopify/polaris";
import { FilterModal } from "./modal";

const defaultWaterHeaterImage = {
  gas: `${process.env.REACT_APP_GCS_PRODUCT_IMAGE_PATH}30_gallon_gas.png`,
  electric: `${process.env.REACT_APP_GCS_PRODUCT_IMAGE_PATH}30_gallon_electric.png`,
}

export const TankCatalogFilter = ({ criteria, productCatalog, onChange }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleChange = (newCriteria) => {
    if (isModalOpen) {
      onChange(newCriteria);
    }

    setIsModalOpen((oldState) => !oldState);
  };

  const getTitle = useCallback(() => {
    if (productCatalog.length === 0) {
      return `${criteria.powerType} water heater`;
    }

    return `${productCatalog[0].product.unitType} ${criteria.powerType} water heater`;
  }, [criteria.powerType, productCatalog]);

  const getLogo = useCallback(() => {
    if (productCatalog.length === 0) {
      return defaultWaterHeaterImage[criteria.powerType];
    }

    return productCatalog[0].product.productImage;
  }, [criteria.powerType, productCatalog]);

  return (
    <div className={styles.TankCatalogFilter}>
      <div className={styles.TankCatalogFilterLogo}>
        <Thumbnail source={getLogo()} alt="Water Heater Logo" />
      </div>

      <div className={styles.TankCatalogFilterContent}>
        <span className={styles.TankCatalogFilterContentTitle}>Recommended product</span>
        <span>{getTitle()}</span>
      </div>

      <Button onClick={handleChange} size="slim">
        Edit
      </Button>

      <FilterModal
        criteria={criteria}
        handleChange={handleChange}
        active={isModalOpen}/>
    </div>
  );
}
TankCatalogFilter.propTypes = {
  criteria: PropTypes.shape({
    homeType: PropTypes.string.isRequired,
    powerType: PropTypes.string.isRequired,
    bathroomCoverage: PropTypes.string.isRequired,
    stairAccess: PropTypes.string.isRequired,
    tankType: PropTypes.string.isRequired,
    currentLocation: PropTypes.string,
    relocation: PropTypes.string,
  }).isRequired,
  productCatalog: PropTypes.arrayOf(PropTypes.shape(
    {
      id: PropTypes.number.isRequired,
      homeTypes: PropTypes.arrayOf(PropTypes.string).isRequired,
      currentLocation: PropTypes.string.isRequired,
      desiredLocation: PropTypes.string.isRequired,
      isPopular: PropTypes.bool.isRequired,
      basePrice: PropTypes.number.isRequired,
      warranty: PropTypes.number.isRequired,
      totalIncentives: PropTypes.number.isRequired,
      socalGasRebates: PropTypes.number.isRequired,
      federalTaxCredit: PropTypes.number.isRequired,
      finalPrice: PropTypes.number.isRequired,
      installationPrice: PropTypes.number.isRequired,
      stairPrice: PropTypes.number.isRequired,
      product: PropTypes.shape({
        id: PropTypes.number.isRequired,
        unitType: PropTypes.string.isRequired,
        title: PropTypes.string.isRequired,
        brand: PropTypes.string.isRequired,
        description: PropTypes.string.isRequired,
        tankType: PropTypes.string.isRequired,
        productImage: PropTypes.string.isRequired,
        powerType: PropTypes.string.isRequired,
        bathroomCoverages: PropTypes.arrayOf(PropTypes.number).isRequired,
        waterFlowGpm: PropTypes.string.isRequired,
        powerOutputBtu: PropTypes.string,
        homeCoverage: PropTypes.string.isRequired,
      }).isRequired,
    }
  )).isRequired,
  onChange: PropTypes.func.isRequired,
};
