import React from "react";
import PropTypes from "prop-types";

import { AlphaStack, List, Modal, Text } from "@shopify/polaris";

export const RebateModal = ({ modalOpen, handleCloseModal }) => {
  return (
    <Modal
      open={modalOpen}
      onClose={handleCloseModal}
      title={<h1>Rebates & Credits</h1>}
    >
      <Modal.Section>
        <AlphaStack gap={"5"}>
          <Text as={"p"} variant={"bodyMd"}>
            HomeBreeze makes it easy for you to claim these rebates. After your
            installation, we send you everything you need to claim them easily.
          </Text>
          <AlphaStack gap={"2"}>
            <Text variant="headingMd" as="h3">
              Federal Tax Credits
            </Text>
            <Text as={"p"} variant={"bodyMd"}>
              The federal government offers a tax credit of 30% of the purchase
              price up to $600 for customers who upgrade their tank to energy
              efficient tankless water heaters New water heater must have an
              energy factor {">"}= 0.95 Limit 1 rebate per household Must be an
              existing home (no new construction) and your principle residence
              Must replace a tank type water heater with tankless Proof of
              purchase required
            </Text>
          </AlphaStack>
          <AlphaStack gap={"2"}>
            <Text variant={"headingMd"} as={"h3"}>
              California Energy Commission Rebates
            </Text>
            <AlphaStack gap={"1"}>
              <Text as={"p"} variant={"bodyMd"}>
                Socal gas offers rebates up to $1,000 for customers upgrading
                their homes from tank to energy efficient tankless water heaters
              </Text>
              <List type="bullet" spacing="extraTight">
                <List.Item>
                  Cannot be combined with other utility rebates
                </List.Item>
                <List.Item>Limit 1 rebate per customer</List.Item>
                <List.Item>
                  Must be a residential customer of a participating utility
                </List.Item>
                <List.Item>Must be installed in service territory</List.Item>
                <List.Item>Must replace a tank-type water heater</List.Item>
                <List.Item>Proof of purchase required</List.Item>
              </List>
            </AlphaStack>
          </AlphaStack>
          <AlphaStack gap={"2"}>
            <Text variant={"headingMd"} as={"h3"}>
              City of Long Beach Utilities Rebates
            </Text>
            <AlphaStack gap={"1"}>
              <Text as={"h5"} variant={"headingXs"}>
                TANKLESS
              </Text>
              <List type="bullet" spacing="extraTight">
                <List.Item>$600 rebate with a UEF of 0.82 - 0.86</List.Item>
                <List.Item>$800 rebate with a UEF of 0.87 - 0.94</List.Item>
                <List.Item>$1,000 rebate with a UEF of 0.96 or above</List.Item>
              </List>
            </AlphaStack>
            <AlphaStack gap={"1"}>
              <Text as={"h5"} variant={"headingXs"}>
                TANK
              </Text>
              <List type="bullet" spacing="extraTight">
                <List.Item>
                  $115 rebate on ENERGY STAR certified high-efficiency natural
                  gas storage water heaters (up to 55 gallons)
                </List.Item>
              </List>
            </AlphaStack>
          </AlphaStack>
        </AlphaStack>
      </Modal.Section>
    </Modal>
  );
};

RebateModal.propTypes = {
  modalOpen: PropTypes.bool.isRequired,
  handleCloseModal: PropTypes.func.isRequired,
};
