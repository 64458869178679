import React from "react";
import PropTypes from "prop-types";

import { Button, ChoiceList, Icon } from "@shopify/polaris";
import { FilterMajor } from '@shopify/polaris-icons';

import {
  HomeTypes,
  PowerType,
  BathroomCoverage,
  StairType,
  CurrentLocationType,
  RelocationType,
} from "@src/config/constants";

import styles from "./styles.module.scss";

export const TanklessMobileCatalogFilter = ({
  criteria,
  onChange,
}) => {
  const [isMobileMenuOpen, setMobileMenuOpen] = React.useState(false);

  const FilterIcon = () => (
    <Icon source={FilterMajor} color="base" />
  );

  function handleMobileMenu() {
    setMobileMenuOpen(!isMobileMenuOpen);
  }

  function handleChange(index, value) {
    document.activeElement?.blur();

    let newValues = criteria;
    newValues[index] = value[0];
    onChange({ ...newValues });
    setMobileMenuOpen(false);
  }

  const Menu = (
    <div className={styles.TanklessMobileCatalogFilterChoices}>
      <ChoiceList
        title="Home Type"
        choices={HomeTypes}
        selected={criteria["homeType"]}
        onChange={(value) => handleChange("homeType", value)}
      />
      <ChoiceList
        title="Power Type"
        choices={PowerType}
        selected={criteria["powerType"]}
        onChange={(value) => handleChange("powerType", value)}
      />
      <ChoiceList
        title="Coverage"
        choices={BathroomCoverage}
        selected={criteria["bathroomCoverage"]}
        onChange={(value) => handleChange("bathroomCoverage", value)}
      />
      <ChoiceList
        title="Stair access"
        choices={StairType.map((item) => ({
          ...item,
          label: item.description !== undefined ?
            `${item.label} (${item.description})` :
            item.label,
        }))}
        selected={criteria["stairAccess"]}
        onChange={(value) => handleChange("stairAccess", value)}
      />
      <ChoiceList
        title="Current Location"
        choices={CurrentLocationType}
        selected={criteria["currentLocation"]}
        onChange={(value) => handleChange("currentLocation", value)}
      />
      <ChoiceList
        title="Relocation"
        choices={RelocationType}
        selected={criteria["relocation"]}
        onChange={(value) => handleChange("relocation", value)}
      />
    </div>
  );

  return (
    <div className={styles.TanklessMobileCatalogFilter}>
      <Button
        icon={FilterIcon}
        fullWidth
        onClick={() => handleMobileMenu()}>
        Filters
      </Button>
      {isMobileMenuOpen && (
        Menu
      )}
    </div>
  );
}
TanklessMobileCatalogFilter.propTypes = {
  criteria: PropTypes.shape({
    homeType: PropTypes.string.isRequired,
    powerType: PropTypes.string.isRequired,
    bathroomCoverage: PropTypes.string.isRequired,
    stairAccess: PropTypes.string.isRequired,
    tankType: PropTypes.string.isRequired,
    currentLocation: PropTypes.string.isRequired,
    relocation: PropTypes.string.isRequired,
  }).isRequired,
  onChange: PropTypes.func.isRequired,
};
