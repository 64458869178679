import React, { useCallback, useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";

import { BookingFlowFrame, BookingFlowPage } from "@src/components";
import { API_BASE_URL, AppBaseUrl } from "@src/config";
import {
  ProductSearchResult,
  Criteria,
  segmentClient,
  useBooking,
} from "@src/core";

import { ChooseProductTankless } from "./tankless";
import { ChooseProductTank } from "./tank";

export function ChooseProductPage({}) {
  const [searchParams, setSearchParams] = useSearchParams();
  const { setUrlToken, useAsyncError } = useBooking();
  const urlToken = searchParams.get("url_token");
  const throwError = useAsyncError();

  React.useEffect(() => {
    if (!urlToken) {
      location.href = AppBaseUrl;
    }
  }, []);

  const [criteria, setCriteria] = useState({
    id: -1,
    homeType: "single_family",
    powerType: "gas",
    bathroomCoverage: "1",
    stairAccess: "no",
    currentLocation: "garage",
    relocation: "current",
    customer: -1,
  });

  useEffect(() => {
    if (!(criteria.customer > 0)) {
      return;
    }

    let identity = "";
    const user = segmentClient.user();
    if (user !== null) {
      identity = user.id();
    }

    if (identity !== urlToken) {
      segmentClient.setAnonymousId(urlToken);
      segmentClient.identify(urlToken);
    }
  }, [urlToken, criteria]);

  const [products, setProducts] = React.useState(
    ProductSearchResult.fromJSONArray([])
  );
  const [isLoading, setIsLoading] = React.useState(true);
  const [isRefreshAvailable, setIsRefreshAvailable] = React.useState(false);

  const fetchData = async () => {
    await fetch(`${API_BASE_URL}booking/product/selected/${urlToken}`)
      .then((response) => {
        if (response.ok) {
          return response.json();
        }

        throw new Error("Something went wrong loading Product Catalog");
      })
      .then((responseJson) => {
        const {
          product_catalog: productCatalog,
          product_criteria: productCriteria,
        } = responseJson;

        setUrlToken(urlToken);

        setProducts(ProductSearchResult.fromJSONArray(productCatalog));
        setCriteria(Criteria.fromJSON(productCriteria));
        setIsRefreshAvailable(true);
      })
      .catch((error) => {
        throwError(error);
      });
  };

  useEffect(() => {
    fetchData()
      .then(() => {
        setIsLoading(false);
      })
      .catch(console.error);
  }, []);

  const updateProductCriteria = (newCriteria) => {
    const settings = {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(Criteria.toJSON(newCriteria)),
    };

    fetch(`${API_BASE_URL}booking/update_product_criteria/`, settings)
      .then((response) => {
        if (response.ok) {
          return;
        }
        throw new Error("Something went wrong updating Product Criteria");
      })
      .then(() => {
        setCriteria(newCriteria);
        setIsRefreshAvailable(false);

        fetchData()
          .then(() => {
            setIsRefreshAvailable(true);
          })
          .catch(console.error);
      })
      .catch((error) => {
        throwError(error);
      });
  };

  const ChooseTanklessProductContent = useCallback(() => {
    if (criteria.tankType === undefined) {
      return <></>;
    }


    return criteria.tankType === "tankless" ? (
      <ChooseProductTankless
        products={products}
        criteria={criteria}
        setCriteria={updateProductCriteria}
        isLoading={isLoading}
        urlToken={urlToken}
        isRefreshAvailable={isRefreshAvailable}
      />
    ) : (
      <ChooseProductTank
        products={products}
        criteria={criteria}
        setCriteria={updateProductCriteria}
        isLoading={isLoading}
        urlToken={urlToken}
        isRefreshAvailable={isRefreshAvailable}
      />
    );
  }, [criteria, products, isLoading, urlToken, isRefreshAvailable]);

  const chooseProductPage = (
    <BookingFlowPage title="Your Online Estimates" titleOnly>
      <ChooseTanklessProductContent />
    </BookingFlowPage>
  );

  return <BookingFlowFrame>{chooseProductPage}</BookingFlowFrame>;
}
