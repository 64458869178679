import React from "react";
import { Button, Card } from "@shopify/polaris";

import styles from "./index.module.scss";

export const TankCustomCard = ({ }) => {
  return (
    <div className={`${styles.card} ${styles["black"]}`}>
      <Card>
        <div className={styles.cardHeader}>
          <div className={styles.cardHeaderTitle}>
            <h3>Want to customize? Call or message us.</h3>
          </div>
        </div>
        <div className={styles.cardBody}>
          <span>We can customize upgrades to fit your needs</span>
        </div>
        <div className={styles.cardFooter}>
          <Button url="tel:3239666344">(323) 766-6344</Button>
        </div>
      </Card>
    </div>
  );
};
