import React from "react";
import PropTypes from "prop-types";
import { Link } from "@shopify/polaris";
import { isSafari } from "@src/utils";

export function Address({ address }) {
  const baseLink = () => {
    if (isSafari) {
        return "https://maps.apple.com/maps?q=";
    }

    return "https://maps.google.com/?q=";
  };

  return (
    <Link removeUnderline={true} url={baseLink() + address}>
      {address}
    </Link>
  );
}
Address.propTypes = {
  address: PropTypes.string.isRequired,
};
