import React, { useEffect } from "react";
import PropTypes from "prop-types";

const ACCOUNT_ID = "8e7313fc946fd3d8c15c886f791a7ab8";

export function EmbedSocial({
  refId,
}) {
  const loadScript = (src) => {
    const tag = document.createElement("script");
    tag.type = "text/javascript";
    tag.src = src;

    document.body.appendChild(tag);
  }

  useEffect(() => {
    loadScript(`https://reviewsonmywebsite.com/js/v2/embed.js?id=${ACCOUNT_ID}`);
  }, []);

  return (
    <div data-romw-token={refId}></div>
  );
}
EmbedSocial.propTypes = {
  refId: PropTypes.string.isRequired,
};
