import { BaseModel } from "@src/core/models/base";
import { SupplyHouse, TSupplyHouse } from "./supply-house";

/**
 * @typedef TMaterialList
 * @property {string} orderNumber
 * @property {TSupplyHouse} supplyHouse
 */
export class MaterialList extends BaseModel {
  orderNumber;
  supplyHouse;

  /** @returns {Array<MaterialList>} */
  static fromJSONArray(json) {
    const materialLists = [];
    for (const item of json) {
      const materialList = new MaterialList();
      materialList.orderNumber = item.order_number;
      materialList.supplyHouse = SupplyHouse.fromJSON(item.supply_house);

      materialLists.push(materialList);
    }

    return materialLists;
  }
}
