import React from "react";
import { EmptyState } from "@shopify/polaris";

import { segmentClient } from "@src/core";

export function AppErrorPage() {
  // user can be null
  // @see https://github.com/homebreeze/hb/issues/570
  const user = segmentClient.user();
  const urlToken = user !== null ? user.anonymousId() : "";

  return (
    <EmptyState
      heading="An unexpected error occurred."
      image="/No Results@1x.svg"
      fullWidth
    >
      Our team has been notified
      {user !== null && `, error id: ${urlToken}`}.
      <br />
      <br />
      Please try refreshing the page
      <br />
      <br />
      <small>
        {"If this error persists, please reach out to us at "}
        <a href="mailto:care@homebreeze.com">care@homebreeze.com</a>.
      </small>
    </EmptyState>
  );
}
