import React from "react";
import PropTypes from "prop-types";

import { Select } from "@src/components/select";

import {
  HomeTypes,
  PowerType,
  BathroomCoverage,
  StairType,
  CurrentLocationType,
  RelocationType,
} from "@src/config/constants";

import styles from "./styles.module.scss";

export const TanklessDesktopCatalogFilter = ({
  criteria,
  onChange,
}) => {
  function handleChange(index, value) {
    document.activeElement?.blur();

    let newValues = criteria;
    newValues[index] = value;
    onChange({ ...newValues });
  }

  return (
    <div className={styles.TanklessDesktopCatalogFilter}>
      <Select
        items={HomeTypes}
        selected={criteria["homeType"]}
        title="HOME TYPE"
        onChange={(value) => handleChange("homeType", value)}
      />
      <Select
        items={PowerType}
        selected={criteria["powerType"]}
        title="POWER TYPE"
        onChange={(value) => handleChange("powerType", value)}
      />
      <Select
        items={BathroomCoverage}
        selected={criteria["bathroomCoverage"]}
        title="COVERAGE"
        onChange={(value) => handleChange("bathroomCoverage", value)}
      />
      <Select
        items={CurrentLocationType}
        selected={criteria["currentLocation"]}
        title="CURRENT LOCATION"
        onChange={(value) => handleChange("currentLocation", value)}
      />
      <Select
        items={RelocationType}
        selected={criteria["relocation"]}
        title="RELOCATION"
        onChange={(value) => handleChange("relocation", value)}
      />
      <Select
        items={StairType}
        selected={criteria["stairAccess"]}
        title="STAIR ACCESS"
        onChange={(value) => handleChange("stairAccess", value)}
      />
    </div>
  );
};
TanklessDesktopCatalogFilter.propTypes = {
  criteria: PropTypes.shape({
    homeType: PropTypes.string.isRequired,
    powerType: PropTypes.string.isRequired,
    bathroomCoverage: PropTypes.string.isRequired,
    stairAccess: PropTypes.string.isRequired,
    tankType: PropTypes.string.isRequired,
    currentLocation: PropTypes.string.isRequired,
    relocation: PropTypes.string.isRequired,
  }).isRequired,
  onChange: PropTypes.func.isRequired,
};
