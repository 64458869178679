import React from "react";
import PropTypes from "prop-types";
import "./styles.scss";

import {
  Box,
  Button,
  Icon,
  Inline,
  Text,
} from "@shopify/polaris";
import { CircleInformationMajor } from "@shopify/polaris-icons";
import { formatCurrencyDollar } from "@src/utils";

export const RebateCallout = ({handleOpenRebateModal, maxRebateAmount}) => {
  const InfoIcon = <Icon source={CircleInformationMajor} color="primary" />;

  return (
    <Box background="surface-highlight-subdued" border="base" borderRadius="2" padding="4">
      <Inline align="space-between" blockAlign="center" gap="4">
        <Inline align="start" gap="4">
          <div
            style={{
              height: "20px",
              width: "20px",
            }}
          >
            {InfoIcon}
          </div>
          <Text variant={"headingMd"} as={"h4"}>
            Get up to {formatCurrencyDollar(maxRebateAmount)} in rebates and credits
          </Text>
        </Inline>
        <Button outline onClick={handleOpenRebateModal}>View Details</Button>
      </Inline>
    </Box>
  );
};

RebateCallout.propTypes = {
  handleOpenRebateModal: PropTypes.func.isRequired,
};
