import React from "react";
import { Document, Page } from "react-pdf/dist/esm/entry.webpack5";
import PropTypes from "prop-types";
import "react-pdf/dist/esm/Page/AnnotationLayer.css";
import "./styles.scss";

/**
 * @param {object} details
 * @param {object} details.document
 */
export function PDFViewer({ document }) {
  const [numPages, setNumPages] = React.useState(0);
  function onPDFLoadSuccess({ numPages }) {
    setNumPages(numPages);
  }

  return (
    <div className="pdf-viewer-modal-wrapper">
      <Document file={document} noData="Loading PDF..." onLoadSuccess={onPDFLoadSuccess}>
        {[...new Array(numPages)].map((_, index) => (
          <Page key={`page_${index + 1}`} pageNumber={index + 1}/>
        ))}
      </Document>
    </div>
  );
}
PDFViewer.propTypes = {
  document: PropTypes.object.isRequired,
};
