import { BaseCaseModel } from "@src/core/models/base";

export class Product extends BaseCaseModel {
    id;
    bathroomCoverages;
    brand;
    description;
    homeCoverage;
    powerOutputBtu;
    powerType;
    productImage;
    tankType;
    title;
    unitType;
    waterFlowGpm;

    /**
     * Extract tank parameters from product description.
     * These parameters are used for the card configuration:
     * - Theme;
     * - Title;
     * - Call to Action;
     * - List of features;
     *
     * Used for the tank bundles.
     *
     * Example: "-gray-Basic Replacement-Choose Basic-Water Heater-Connectors"
     * Generates: [
     *  "gray",
     *  "Basic Replacement",
     *  "Choose Basic",
     *  "Water Heater",
     *  "Connectors",
     * ]
     *
     * @param {*} product
     * @returns string[]
     */
    static getTankParameters(product) {
        return product.description.split("-").slice(1);
    }
}
