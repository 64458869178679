import React, { useEffect } from "react";

import { ProAppFrame, ProAppPage } from "@src/components";
import { useAuth } from "@src/core";

import "./styles.scss";

export function LogoutPage() {
  const { userLogout } = useAuth();

  useEffect(() => {
    userLogout();
  }, []);

  const logoutContent = (
    <></>
  );

  const loginPage = <ProAppPage title="Logout">{logoutContent}</ProAppPage>;

  return (
    <ProAppFrame hideTopBar hideNavigation>
      {loginPage}
    </ProAppFrame>
  );
}
