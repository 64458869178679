import { ProductItem } from "./item";
import { ProductCatalog } from "./product-catalog";

export class ProductSearchResult extends ProductCatalog {
  items = [];
  tags = [];

  static fromJSON(jsonProductCatalog) {
    const result = super.fromJSON(jsonProductCatalog);

    function capitalizeFirstLetter(string) {
      return string.charAt(0).toUpperCase() + string.slice(1);
    }

    if (result) {
      let productDisplayItems = [
        {
          name: "Type",
          description: capitalizeFirstLetter(jsonProductCatalog.product.tank_type),
        },
      ];

      if (jsonProductCatalog.product.water_flow_gpm !== "null") {
        productDisplayItems.push({
          name: "Gallons Per Minute",
          description: `up to ${jsonProductCatalog.product.water_flow_gpm} gallons/min`,
        });
      }

      function formatPowerOutputBTU(btu) {
        return btu.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      }

      if (jsonProductCatalog.product.power_output_btu !== null) {
        productDisplayItems.push({
          name: "Max power output",
          description: `${formatPowerOutputBTU(jsonProductCatalog.product.power_output_btu)} BTU`,
        });
      }

      productDisplayItems.push({
        name: "Warranty",
        description: `up to ${jsonProductCatalog.warranty} years`,
      });

      if (jsonProductCatalog.uniform_energy_factor !== "") {
        productDisplayItems.push({
          name: "Uniform Energy Factor",
          description: `${jsonProductCatalog.uniform_energy_factor}`,
        });
      }

      result.items = ProductItem.fromJSONArray(productDisplayItems ?? []);

      result.tags = [
        "Water heater",
        "Delivery",
        "Installation",
        "Parts",
        "Disposal",
      ];
    }

    return result;
  }
}
