import React from "react";
import PropTypes from "prop-types";

import { TanklessMobileCatalogFilter } from "./tankless-mobile";
import { TanklessDesktopCatalogFilter } from "./tankless-desktop";

import styles from "./styles.module.scss";
import { TankCatalogFilter } from "./tank";

export function ProductCatalogFilter({
  criteria,
  productCatalog,
  onChange,
}) {
  return (
    <div className={styles.ProductCatalogFilter}>
      {criteria.tankType === "tankless" ? (
        <>
          <div className={styles.ProductCatalogFilterDesktop}>
            <TanklessDesktopCatalogFilter criteria={criteria} onChange={onChange} />
          </div>
          <div className={styles.ProductCatalogFilterMobile}>
            <TanklessMobileCatalogFilter criteria={criteria} onChange={onChange} />
          </div>
        </>
      ) : (
        <div>
          <TankCatalogFilter
            criteria={criteria}
            productCatalog={productCatalog}
            onChange={onChange} />
        </div>
      )}
    </div>
  );
}
ProductCatalogFilter.propTypes = {
  criteria: PropTypes.shape({
    homeType: PropTypes.string.isRequired,
    powerType: PropTypes.string.isRequired,
    bathroomCoverage: PropTypes.string.isRequired,
    stairAccess: PropTypes.string.isRequired,
    tankType: PropTypes.string.isRequired,
    currentLocation: PropTypes.string,
    relocation: PropTypes.string,
  }).isRequired,
  productCatalog: PropTypes.arrayOf(PropTypes.shape(
    {
      id: PropTypes.number.isRequired,
      homeTypes: PropTypes.arrayOf(PropTypes.string).isRequired,
      currentLocation: PropTypes.string.isRequired,
      desiredLocation: PropTypes.string.isRequired,
      isPopular: PropTypes.bool.isRequired,
      basePrice: PropTypes.number.isRequired,
      warranty: PropTypes.number.isRequired,
      totalIncentives: PropTypes.number.isRequired,
      socalGasRebates: PropTypes.number.isRequired,
      federalTaxCredit: PropTypes.number.isRequired,
      finalPrice: PropTypes.number.isRequired,
      installationPrice: PropTypes.number.isRequired,
      stairPrice: PropTypes.number.isRequired,
      product: PropTypes.shape({
        id: PropTypes.number.isRequired,
        unitType: PropTypes.string.isRequired,
        title: PropTypes.string.isRequired,
        brand: PropTypes.string.isRequired,
        description: PropTypes.string.isRequired,
        tankType: PropTypes.string.isRequired,
        productImage: PropTypes.string.isRequired,
        powerType: PropTypes.string.isRequired,
        bathroomCoverages: PropTypes.arrayOf(PropTypes.number).isRequired,
        waterFlowGpm: PropTypes.string.isRequired,
        powerOutputBtu: PropTypes.string,
        homeCoverage: PropTypes.string.isRequired,
      }).isRequired,
    }
  )),
  onChange: PropTypes.func.isRequired,
};
