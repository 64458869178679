import React from "react";

import iconStar from "@src/assets/images/booking/star-yellow.svg";
import { EmbedSocial } from "./embed-social";
import "./styles.scss";

export function SocialReviews({ }) {
  return (
    <section className="customers-love-us">
      <h2>Customers love us</h2>
      <div className="description">
        <img src={iconStar} alt="" />
        5.0 out of 5.0 stars
      </div>
      <div className="social-reviews">
        <EmbedSocial refId="woJJbt6NdSWYySY2xKHTVkNBVzU5EQHPwPYHnlawRgj45LFw05"/>
      </div>
    </section>
  );
}
SocialReviews.propTypes = {};
