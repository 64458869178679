import React, { useState, useCallback } from "react";
import { Frame as PolarisFrame, Navigation } from "@shopify/polaris";
import { HomeMinor, OrdersMinor, ProductsMinor } from "@shopify/polaris-icons";
import { createBreakpoint } from "react-use";
import { SmallScreenSize } from "@src/config";
import { ProAppTopBar } from "./top-bar";
import "./styles.scss";

const useBreakpoint = createBreakpoint({
  M: SmallScreenSize + 1,
  S: SmallScreenSize,
});

export const ProAppFrame = ({
  hideTopBar = false,
  hideNavigation = true,
  ...props
}) => {
  const breakpoint = useBreakpoint();
  const [mobileNavigationActive, setMobileNavigationActive] = useState(false);

  const toggleMobileNavigationActive = useCallback(
    () =>
      setMobileNavigationActive(
        (mobileNavigationActive) => !mobileNavigationActive
      ),
    []
  );

  const logo = {
    width: 175,
    topBarSource: "/logo+name.svg",
    contextualSaveBarSource: "/logo+name.svg",
    url: "/site/pro/job-list/",
    accessibilityLabel: "HomeBreeze",
  };

  const customPropertyOverrideClasses =
    "CustomFrameOverrides";

  const navigationMarkup = hideNavigation ? (
    false
  ) : (
    <Navigation location="/">
      <Navigation.Section
        items={[
          {
            url: "/",
            label: "Jobs",
            icon: HomeMinor,
          },
          {
            url: "/path/to/place",
            label: "Payments",
            icon: OrdersMinor,
            badge: "15",
          },
          {
            url: "/path/to/place",
            label: "Messages",
            icon: ProductsMinor,
          },
        ]}
      />
    </Navigation>
  );

  const topBarMarkup = hideTopBar
    ? false
    : ProAppTopBar(breakpoint, toggleMobileNavigationActive);

  return (
    <div className="ProApp">
      <div className={customPropertyOverrideClasses}>
        <PolarisFrame
          topBar={topBarMarkup}
          navigation={navigationMarkup}
          showMobileNavigation={mobileNavigationActive}
          onNavigationDismiss={toggleMobileNavigationActive}
          logo={logo}
          {...props}
          >
          {props.children}
        </PolarisFrame>
      </div>
    </div>
  );
};
