import { BaseModel } from "@src/core/models/base";

/**
 * @typedef TPhoto
 * @property {number} id
 * @property {string} uri
 * @property {string} name
 */
export class Photo extends BaseModel {
  id;
  name;
  uri;

  /** @returns {Photo} */
  static fromJSON(json) {
    const photo = new Photo();
    photo.id = json.id;
    photo.name = json.name;
    photo.uri = json.uri;

    return photo;
  }

  /** @returns {Array<Photo>} */
  static fromJSONArray(json) {
    const photos = [];
    json.forEach((item) => {
      photos.push(Photo.fromJSON(item));
    });

    return photos;
  }
}
