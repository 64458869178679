export class Storage {
  static Keys = Object.freeze({
    AccessToken: "homebreeze-access-token",
    RefreshToken: "homebreeze-refresh-token",
    proUser: "homebreeze-pro-user"
  });

  static get(key) {
    return localStorage.getItem(key);
  }
  static set(key, value) {
    if (value) localStorage.setItem(key, value);
    else localStorage.removeItem(key);
  }

  static get accessToken() {
    return Storage.get(Storage.Keys.AccessToken);
  }
  static set accessToken(value) {
    Storage.set(Storage.Keys.AccessToken, value);
  }

  static get refreshToken() {
    return Storage.get(Storage.Keys.RefreshToken);
  }
  static set refreshToken(value) {
    Storage.set(Storage.Keys.RefreshToken, value);
  }

  static get proUser() {
    return Storage.get(Storage.Keys.proUser);
  }
  static set proUser(value) {
    Storage.set(Storage.Keys.proUser, value);
  }
}
