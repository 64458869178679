import { BaseModel } from "@src/core/models/base";

/**
 * @typedef TSupplyHouse
 * @property {string} name
 * @property {string} address
 */
export class SupplyHouse extends BaseModel {
  name;
  address;

  /** @returns {SupplyHouse} */
  static fromJSON(json) {
    const supplyHouse = new SupplyHouse();
    supplyHouse.name = json.name;
    supplyHouse.address = json.address;

    return supplyHouse;
  }
}
