import React from "react";

import PropTypes from "prop-types";

import styles from "./index.module.scss";
import { Badge, Button, Card, Icon } from "@shopify/polaris";
import { TickMinor } from "@shopify/polaris-icons";

import { formatCurrency } from "@src/utils/index";

export const TankBundleCard = ({
  isPopular,
  onSucess,
  parameters,
  productCatalogId,
  price,
}) => {
  const TickIcon = () => (
    <Icon color="base" source={TickMinor} />
  );

  const theme = parameters[0];
  const title = parameters[1];
  const callToAction = parameters[2];
  const features = parameters.slice(3);

  return (
    <div className={`${styles.card} ${styles[theme]}`}>
      <Card>
        {isPopular && (
          <div className={styles.cardPopular}>
            <Badge>Popular Choice</Badge>
          </div>
        )}
        <div className={styles.cardHeader}>
          <div className={styles.cardHeaderTitle}>
            <h3>{title}</h3>
          </div>
          <div className={styles.cardHeaderPrice}>
            <h3>{formatCurrency(price)}</h3>
            <span>pay after installation</span>
          </div>
        </div>
        <div className={styles.cardBody}>
          <ul className={styles.cardBodyFeatures}>
            {features.map((feature, index) => (
              <li className={styles.cardBodyFeaturesFeature} key={index}>
                <Badge icon={TickIcon}>{feature}</Badge>
              </li>
            ))}
          </ul>
        </div>
        <div className={styles.cardFooter}>
          <Button
            fullWidth
            onClick={() => onSucess(productCatalogId)}>
            {callToAction}
          </Button>
        </div>
      </Card>
    </div>
  );
};
TankBundleCard.propTypes = {
  parameters: PropTypes.arrayOf(PropTypes.string).isRequired,
  isPopular: PropTypes.bool.isRequired,
  onSucess: PropTypes.func.isRequired,
  productCatalogId: PropTypes.number.isRequired,
  price: PropTypes.number.isRequired,
};
