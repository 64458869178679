import { GoogleMapKey } from "../config";
import geocoder from "google-geocoder";

var geo = geocoder({
  key: GoogleMapKey,
});

export function getGeoInformation(address, cb) {
  geo.find(address, function (err, res) {
    cb(res);
  });
}
