import React from "react";
import { TopBar as PolarisTopBar } from "@shopify/polaris";

import { API_BASE_URL, WEBFLOW_URL } from "@src/config";
import { useBooking } from "@src/core";

import "./styles.scss";

export const BookingFlowTopBar = (breakpoint) => {
  const { urlToken } = useBooking();
  const initiateRestart = React.useCallback(() => {
    fetch(`${API_BASE_URL}booking/restart_flow/${urlToken}`);
    location.href = `${WEBFLOW_URL}?url_token=${urlToken}`;
  }, [urlToken]);

  const buttonClassName =
    breakpoint == "S"
      ? "Polaris-Button"
      : "Polaris-Button Polaris-Button--sizeLarge";

  const secondaryMenuMarkup = (
    <PolarisTopBar.Menu
      activatorContent={
        <span className={buttonClassName}>
          <span className="Polaris-Button__Content">
            <span className="Polaris-Button__Text">Restart</span>
          </span>
        </span>
      }
      onOpen={initiateRestart}
    />
  );

  return <PolarisTopBar secondaryMenu={secondaryMenuMarkup}></PolarisTopBar>;
};
