import React from "react"
import PropTypes from "prop-types";

import "./styles.scss"
import iconCaret from "@src/assets/images/booking/caret-up-16px-22252D.svg"

export function ExpandableContainer({
  title,
  children,
}) {
  const [isExpanded, setExpanded] = React.useState(false);

  return (
    <div className={`expandable-container ${isExpanded && "expanded"}`} onClick={() => setExpanded(!isExpanded)}>
      <button className="btn-expand">
        <img src={iconCaret} alt="Expand/Collapse"/>
      </button>
      <div className="title">
        {title}
      </div>
      <div className="description">
        {children}
      </div>
    </div>
  );
}
ExpandableContainer.propTypes = {
  title: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
};
