import { BaseCaseModel } from "./base";
import { Criteria, SelectedProduct } from "@src/core";

export class CalendlyInvitee extends BaseCaseModel {
  id;
  email;
  firstName;
  lastName;
  rescheduleUrl;
  uri;
  uuid;
}

export class CalendlyEvent extends BaseCaseModel {
  id;
  name;
  startTime;
  uri;
  location;
  invitee = new CalendlyInvitee();
}

export class Customer extends BaseCaseModel {
  id = null;
  firstName;
  lastName;
  email;
  phone;
  address;
  aptUnit = "";
  city = "";
  zipCode = "";
  gateCode = "";
  urlToken = "";

  static identityJSON(instance) {
    return {
      firstName: instance.firstName,
      lastName: instance.lastName,
      email: instance.email,
      phone: instance.phone,
      address: instance.address,
      aptUnit: instance.aptUnit,
      city: instance.city,
      zipCode: instance.zipCode,
      gateCode: instance.gateCode,
    };
  }
}

export class CalendlyResponse extends Customer {
  calendlyEvent = new CalendlyEvent();
  productCriteria = new Criteria();
  selectedProduct = new SelectedProduct();
}
