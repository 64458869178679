import React from "react";
import PropTypes from "prop-types";
import { Button, Stack, Icon, Badge } from "@shopify/polaris";
import { CaretDownMinor, CaretUpMinor, TickSmallMinor } from "@shopify/polaris-icons";
import { formatCurrency } from "@src/utils";

import "./styles.scss";

export const SelectedProductCard = ({
  onClickSelectedProduct,
  selectedProduct,
}) => {
  const [isRebateOpen, setIsRebateOpen] = React.useState(false);
  const handleRebateToggle = React.useCallback(() => setIsRebateOpen((open) => !open), []);

  const { productCatalog } = selectedProduct;
  const { product } = productCatalog;

  const tags = ["Water Heater & Parts", "Delivery", "Installation", "Disposal"];
  const productTankTypeCopy =
    product.tankType == "tankless"
      ? "Tankless Water Heater"
      : "Tank Water Heater";

  const finalPrices = {
    installationCost: formatCurrency(productCatalog.installationPrice),
    totalIncentives: formatCurrency(productCatalog.totalIncentives),
    socalGasRebates: formatCurrency(productCatalog.socalGasRebates),
    federalTaxCredit: formatCurrency(productCatalog.federalTaxCredit),
    priceAfterIncentives: formatCurrency(productCatalog.finalPrice),
  };

  const tickIcon = () => (
    <Icon color={"success"} source={TickSmallMinor} />
  );

  return (
    <div className="selected-product">
      {/* TODO: This should've been a Polaris Card */}
      <Stack horizontal distribution="equalSpacing">
        <Stack.Item>
          <div className="product-name">{product.title}</div>
          <div className="product-type">{productTankTypeCopy}</div>
        </Stack.Item>

        <Button size="slim" onClick={onClickSelectedProduct}>
          Change
        </Button>
      </Stack>

      <Stack horizontal distribution="equalSpacing" wrap={false}>
        <div>Due before installation</div>
        <div className="product-price">$0.00</div>
      </Stack>

      <Stack horizontal distribution="equalSpacing" wrap={false}>
        <div>Due upon installation</div>
        <div className="product-price">{finalPrices.installationCost}</div>
      </Stack>

      {productCatalog.totalIncentives != 0 && (
        <>
          <div className="sub-heading">After Installation</div>
          <Stack horizontal distribution="equalSpacing">
            <Stack.Item>
              <div className="summary-rebate" onClick={handleRebateToggle}>
                <u>Rebates &amp; credits</u>
                {isRebateOpen && (
                  <Icon source={ CaretUpMinor } color="base" />
                )}
                {!isRebateOpen && (
                  <Icon source={ CaretDownMinor } color="base" />
                )}
              </div>
            </Stack.Item>
            <Stack.Item>
              <div className="product-price">-{finalPrices.totalIncentives}</div>
            </Stack.Item>
          </Stack>

          {isRebateOpen && (
            <>
              {productCatalog.socalGasRebates != 0 && (
                <Stack horizontal distribution="equalSpacing">
                  <span>SoCal gas rebate</span>
                  <span>-{finalPrices.socalGasRebates}</span>
                </Stack>
              )}
              {productCatalog.federalTaxCredit != 0 && (
                <Stack horizontal distribution="equalSpacing">
                  <span>Federal tax credit</span>
                  <span>-{finalPrices.federalTaxCredit}</span>
                </Stack>
              )}
            </>
          )}

          <Stack horizontal distribution="equalSpacing">
            <span>Price after rebates &amp; credits</span>
            <div className="product-price">{finalPrices.priceAfterIncentives}</div>
          </Stack>
        </>
      )}

      {productCatalog.totalIncentives == 0 && (
        <Stack horizontal distribution="equalSpacing" wrap={false}>
          <div>Total</div>
          <div className="product-price">{finalPrices.installationCost}</div>
        </Stack>
      )}

      <div className="sub-heading sub-heading-include">Included in the price</div>
      <Stack horizontal>
        {tags.map((item, idx) => (
          <Badge icon={tickIcon} key={idx}>{item}</Badge>
        ))}
      </Stack>
    </div>
  );
};
SelectedProductCard.propTypes = {
  onClickSelectedProduct: PropTypes.func.isRequired,
  selectedProduct: PropTypes.object.isRequired,
};
