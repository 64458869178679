import React from "react";
import tankBrands from "@src/assets/images/booking/tank-brands-large.svg";

import styles from "./index.module.scss";

export const Brands = () => {
  return (
    <div className={styles.card}>
      <div className={styles.cardHeader}>
        <div className={styles.cardHeaderTitle}>
          <h3>Our Brands</h3>
        </div>
      </div>
      <div className={styles.cardBody}>
        <img
          src={tankBrands}
          srcSet={`${tankBrands} 1200w`}
          alt="Water Heater Tank Logos"/>
      </div>
    </div>
  );
};
