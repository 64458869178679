import React from "react";
import { HelmetProvider } from "react-helmet-async";
import { BrowserRouter } from "react-router-dom";
import Bugsnag from '@bugsnag/js'
import BugsnagPluginReact from '@bugsnag/plugin-react'

import { BookingFlowApp, ProApp } from "@src/apps";
import { AppErrorPage, BookingAppErrorPage } from "@src/components";
import { fontLazyLoader } from "@src/utils";

Bugsnag.start({
  apiKey: process.env.REACT_APP_BUGSNAG_KEY,
  releaseStage: process.env.NODE_ENV,
  plugins: [new BugsnagPluginReact()],
});

const ErrorBoundary = Bugsnag.getPlugin("react").createErrorBoundary(React)

function App() {
  React.useEffect(() => {
    fontLazyLoader.load(
      "https://fonts.googleapis.com/css?family=Inter:100,200,300,400,500,600,700,800,900"
    );
  }, []);

  return (
    <ErrorBoundary FallbackComponent={AppErrorPage}>
      <HelmetProvider>
        <BrowserRouter basename="/site/booking">
          <ErrorBoundary FallbackComponent={BookingAppErrorPage}>
            <BookingFlowApp />
          </ErrorBoundary>
        </BrowserRouter>
        <BrowserRouter basename="/site/pro">
          <ProApp />
        </BrowserRouter>
      </HelmetProvider>
    </ErrorBoundary>
  );
}

export default App;
