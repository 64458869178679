import { Api } from "../api";

/**
 * @param {number} jobId
 * @returns {Promise<any>}
 */
export const getJob = async (jobId) => {
  return await Api.get(`pro/job/${jobId}/`);
};

/**
 * @param {number} jobId
 * @param {string} orderNumber
 * @returns {Promise<any>}
 */
export const getMaterialReceipt = async (jobId, orderNumber) => {
  const encodedOrderNumber = encodeURIComponent(orderNumber);

  return await Api.get(
    `pro/job/${jobId}/materials/${encodedOrderNumber}/`,
    {
      responseType: 'arraybuffer',
    },
  );
};

/**
 * @returns {Promise<any>}
 */
export const getOpenJobs = async () => {
  return await Api.get(`pro/job/open/`);
};

/**
 * @returns {Promise<any>}
 */
export const getCompletedJobs = async () => {
  return await Api.get(`pro/job/completed/`);
};

/**
 * @param {number} jobId
 * @returns {Promise<any>}
 */
export const completeJob = async (jobId) => {
  return await Api.post(`pro/job/${jobId}/complete/`);
};

/**
 * @param {number} photoId
 * @returns {Promise<any>}
 */
export const deletePhoto = async (photoId) => {
  return await Api.delete(`pro/job/photo/${photoId}/`);
};

/**
 * @param {number} jobId
 * @param {object} details
 * @param {string} details.issueType
 * @param {string} details.issueText
 * @returns {Promise<any>}
 */
export const reportJobIssue = async (jobId, { issueType, issueText }) => {
  return await Api.post(`pro/job/${jobId}/report_issue/`, {
    type: issueType,
    text: issueText,
  });
};
