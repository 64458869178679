import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Card, Modal } from "@shopify/polaris";

import { Address, PDFViewer } from "@src/components";
import { getMaterialReceipt, SupplyHouse } from "@src/core";

import "./styles.scss";

/**
 * @param {object} details
 * @param {number} details.jobId
 * @param {string} details.orderNumber
 * @param {import("@src/core/models/supply-house").TSupplyHouse} details.supplyHouse
 */
export function MaterialListLayout({ jobId, orderNumber, supplyHouse }) {
  const [isMaterialListModalActive, setIsMaterialListModalActive] =
    useState(false);

  const [receipt, setReceipt] = useState(null);

  const updateReceipt = async () => {
    if (orderNumber === "") {
      return;
    }

    const response = await getMaterialReceipt(jobId, orderNumber);
    setReceipt(new Blob([response.data], { type: 'application/pdf' }));
  };

  useEffect(() => {
    updateReceipt();
  }, [jobId, orderNumber]);

  const MaterialListModal = (
    <Modal
      onClose={() => setIsMaterialListModalActive(false)}
      open={isMaterialListModalActive}
      secondaryActions={[{
        content: "Close",
        onAction: () => setIsMaterialListModalActive(false),
      }]}
      title="Order receipt"
    >
      <Modal.Section>
        {receipt !== null && (
          <PDFViewer document={receipt}/>
        )}
      </Modal.Section>
    </Modal>
  );

  return (
    <>
      <Card title="Materials">
        <Card.Section title="SUPPLY HOUSE ADDRESS">
          {supplyHouse.name}, <Address address={supplyHouse.address}/>
        </Card.Section>

        {orderNumber !== "" && (
          <Card.Section
            title="ORDER NUMBER"
          >
            <p>{orderNumber}</p>
          </Card.Section>
        )}
        {receipt !== null && (
          <Card.Section title="ORDER RECEIPT">
            <div
              className="pdf-viewer-wrapper"
              onClick={() => {
                setIsMaterialListModalActive(true);
              }}
            >
              <PDFViewer document={receipt}/>
            </div>
          </Card.Section>
        )}
      </Card>

      {MaterialListModal}
    </>
  );
}
MaterialListLayout.propTypes = {
  jobId: PropTypes.number.isRequired,
  orderNumber: PropTypes.string.isRequired,
  supplyHouse: PropTypes.instanceOf(SupplyHouse).isRequired,
};
